import React, { useEffect, useState } from "react";
import SectionHeading from "../SectionHeading/SectionHeading";
import Specialists from "../Specialists/Specialists";
import ReactPaginate from "react-paginate";
import Spacing from "../../Components/Spacing/Spacing";
import { axiosApi, imageBase_URL } from "../../axiosInstance";
import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";

const AvailableHospitals = ({ data }) => {
  const [loading, setLoading] = useState(false);
  const [nearclinic, setNearClinic] = useState([]);
  const [page, setPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [locationQuery, setLocationQuery] = useState("");
  const pageSize = 3;

  function encodeDoctorId(clinicId) {
    return btoa(clinicId.toString()); // Ensure doctorId is a string before encoding
  }

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axiosApi.get(
        `/v1/clinic/getClinicsNearby?page=${page}&pageSize=${pageSize}&location=${locationQuery}&keyword=${searchQuery}`
      );
      setNearClinic(response?.data?.data || []);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (locationQuery) {
      fetchData();
    }
  }, [page, searchQuery, locationQuery]);

  return (
    <>
      {nearclinic && nearclinic.length > 0 && (
        <section id="clinics">
          <div className="st-height-b120 st-height-lg-b80" />
          {/* <SectionHeading title="Hospitals near you" /> */}
          <div className="st-section-heading-title-see_all container">
            <h2 className="st-section-heading-see_all_title">
              Clinics near you
            </h2>
            <Link to="/allclinics">
              <p className="st-section-heading-see_all_para">See All</p>
            </Link>
          </div>
          <div className="st-height-b40 st-height-lg-b40"></div>
          <div className="container">
            {loading ? (
              <div className="custom-loader_container">
                <span className="custom-loader"></span>
              </div>
            ) : (
              <>
                <div className="hospital_near_you-slider-container">
                  {nearclinic?.map((clinic, index) => (
                    <Link
                      key={clinic.clinic_id}
                      to={`/clinic/${encodeDoctorId(clinic.clinic_id)}`}
                    >
                      <div
                        key={index}
                        className="hospital_near_you-card-container"
                      >
                        <img
                          className="hospital_near_you-card-image"
                          // src={
                          //   doctor.photo
                          //     ? `${imageBase_URL}${doctor.photo}`
                          //     : `${process.env.PUBLIC_URL}/images/empty-user.png`
                          // }
                          src={
                            clinic?.banner_img_url
                              ? `${imageBase_URL}${clinic?.banner_img_url}`
                              : `${process.env.PUBLIC_URL}/images/empty-user.png`
                          }
                          alt={clinic.name || "Doctor Image"}
                        />
                        <p className="hospital_near_you-card-name">
                          {clinic?.name}, {clinic?.address}
                        </p>
                        <p className="hospital_near_you-card-location">
                          {clinic?.place}
                        </p>
                        <p className="hospital_near_you-card-distance">
                          <span className="hospital_near_you-card-distance-value">
                            {clinic?.distance} {clinic?.distance_unit}
                          </span>
                          <span className="hospital_near_you-card-distance-text">
                            {" "}
                            away
                          </span>
                        </p>
                      </div>
                    </Link>
                  ))}
                </div>
              </>
            )}
          </div>
        </section>
      )}
    </>
  );
};

export default AvailableHospitals;
