import React, { useContext, useEffect, useState } from "react";
import SectionHeading from "../../Components/SectionHeading/SectionHeading";
import Specialists from "../../Components/Specialists/Specialists";
import ReactPaginate from "react-paginate";
import Spacing from "../../Components/Spacing/Spacing";
import { axiosApi, imageBase_URL } from "../../axiosInstance";
import { Icon } from "@iconify/react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { UserContext } from "../../Contexts/UseContext";

const DoctorsSpecializations = ({ data }) => {
  const { setPageTitle } = useContext(UserContext);

  useEffect(() => {
    setPageTitle("Specialization");
  }, []);

  const params = useParams();
  const navigate = useNavigate();

  let specializationId;
  try {
    specializationId = atob(params.specializationId);
  } catch (error) {
    console.error("Invalid specializationId:", error);
    navigate("/error");
  }

  const [loading, setLoading] = useState(false);
  const [doctors, setDoctors] = useState([]);
  const [doctorSpecialization, setDoctorSpecialization] = useState("");
  const [page, setPage] = useState(1);
  const pageSize = 50;

  function encodeDoctorId(doctorId) {
    return btoa(doctorId.toString());
  }

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axiosApi.get(
        `/v1/doctor/getDoctorsBySpecialization/${specializationId}?pageSize=${pageSize}&page=${page}`
      );
      setDoctors(response?.data?.doctors);
      setDoctorSpecialization(response?.data?.doctors[0]?.specialization)
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    fetchData();
  }, [page]);

  return (
    <section id="doctors">
      <div className="st-height-b120 st-height-lg-b80" />
      <div className="st-section-heading-title-see_all container">
        <h2 className="st-section-heading-see_all_title">{doctorSpecialization} Doctors</h2>
      </div>
      <div className="st-height-b40 st-height-lg-b40"></div>
      {loading ? (
        <div className="custom-loader_container">
          <span className="custom-loader"></span>
        </div>
      ) : (
        <div className="st-available-doctors container">
          {doctors.map((doctors) => (
            <>
              <Link to={`/doctor-profile/${encodeDoctorId(doctors.doctor_id)}`}>
                <div className="st-available-doctors-card st-available-doctors-style">
                  <div className="st-available-doctors-card-details">
                    <img
                      height="98"
                      width="98"
                      src={
                        doctors.photo
                          ? imageBase_URL + doctors.photo
                          : `${process.env.PUBLIC_URL}/images/empty-user.png`
                      }
                      alt={"photo"}
                    />
                    <div className="st-available-doctors-card-info">
                      <p className="st-available-doctors-card-info-name">
                        {doctors.name}
                      </p>
                      <p className="st-available-doctors-card-info-speciality">
                        {doctors.specialization}
                      </p>
                      <p className="st-available-doctors-card-info-experience">
                        {doctors.experience}
                        <span>Years experience</span>
                      </p>
                    </div>
                  </div>
                  <div className="st-available-doctors-location">
                    <span>
                      {doctors.location ||
                        "NMC Royal Medical Centre, Delma, Abu Dhabi"}
                    </span>
                    ,
                    <span className="st-available-doctors-meter">
                      {doctors.distance || "151.06 km"}{" "}
                    </span>
                    <span className="st-available-doctors-away">away</span>
                  </div>
                </div>
              </Link>
            </>
          ))}
        </div>
      )}
      <div className="st-height-b120 st-height-lg-b80" />
    </section>
  );
};

export default DoctorsSpecializations;
