import React, { useContext, useEffect, useState } from "react";
import SectionHeading from "../../Components/SectionHeading/SectionHeading";
import Specialists from "../../Components/Specialists/Specialists";
import ReactPaginate from "react-paginate";
import Spacing from "../../Components/Spacing/Spacing";
import { axiosApi, imageBase_URL } from "../../axiosInstance";
import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";
import { UserContext } from "../../Contexts/UseContext";

const DoctorsAvailable = ({ data }) => {
  const { setPageTitle } = useContext(UserContext);

  useEffect(() => {
    setPageTitle("Doctors");
  }, []);
  const [loading, setLoading] = useState(false);
  const [totalDoctors, setTotalDoctors] = useState(0);
  const [alldoctors, setAllDoctors] = useState([]);
  const [paginationDetails, setPaginationDetails] = useState({});
  const [filteredDoctors, setFilteredDoctors] = useState([]);
  const [page, setPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [locationQuery, setLocationQuery] = useState("");
  const pageSize = 50;

  function encodeDoctorId(doctorId) {
    return btoa(doctorId.toString()); // Ensure doctorId is a string before encoding
  }

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axiosApi.get(
        `/v1/doctor/getallweb?pageSize=${pageSize}&page=${page}&keyword=${searchQuery}&location=${locationQuery}`
      );
      setPaginationDetails(response?.data?.pageInfo);
      setTotalDoctors(response?.data?.Doctors?.count);
      setAllDoctors(response?.data?.Doctors);
      setFilteredDoctors(response?.data?.Doctors);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [page, searchQuery, locationQuery]);

  return (
    <section id="doctors">
      <div className="st-height-b120 st-height-lg-b80" />
      <SectionHeading title="Doctors available near you" />
      <div className="st-height-b40 st-height-lg-b40"></div>
      {loading ? (
        <div className="custom-loader_container">
          <span className="custom-loader"></span>
        </div>
      ) : (
        <div className="st-available-doctors container">
          {alldoctors.map((doctors) => (
            <Link
              key={doctors.doctor_id}
              to={`/doctor-profile/${encodeDoctorId(doctors.doctor_id)}`}
            >
              <div className="st-available-doctors-card st-available-doctors-style">
                <div className="st-available-doctors-card-details">
                  <img
                    height="98"
                    width="98"
                    src={
                      doctors.photo
                        ? imageBase_URL + doctors.photo
                        : `${process.env.PUBLIC_URL}/images/empty-user.png`
                    }
                    alt={"photo"}
                  />
                  <div className="st-available-doctors-card-info">
                    <p className="st-available-doctors-card-info-name">
                      {doctors.name}
                    </p>
                    <p className="st-available-doctors-card-info-speciality">
                      {doctors.specialization || "Monkey D.Luffy"}
                    </p>
                    <p className="st-available-doctors-card-info-experience">
                      {doctors.experience || "32.1"}
                      <span>Years experience</span>
                    </p>
                  </div>
                </div>
                <div className="st-available-doctors-location">
                  <span>
                    {doctors.location ||
                      "NMC Royal Medical Centre, Delma, Abu Dhabi"}
                  </span>
                  ,
                  <span className="st-available-doctors-meter">
                    {doctors.distance || "151.06 km"}{" "}
                  </span>
                  <span className="st-available-doctors-away">away</span>
                </div>
              </div>
            </Link>
          ))}
        </div>
      )}
      <div className="st-height-b120 st-height-lg-b80" />
    </section>
  );
};

export default DoctorsAvailable;
