import React, { useContext, useEffect } from "react";
import Contact from "../../Components/Contact/Contact";
import SectionHeading from "../../Components/SectionHeading/SectionHeading";
import { Link } from "react-router-dom";
import { UserContext } from "../../Contexts/UseContext";

const ContactUsPage = () => {
     const { setPageTitle } = useContext(UserContext);
    
      useEffect(() => {
        setPageTitle("Contact Us");
      }, []);
    
  return (
    <>
      <div className="st-height-b120 st-height-lg-b80" />
      <Contact />
    </>
  );
};

export default ContactUsPage;
