import React, { useState, useEffect, useContext, useRef } from "react";
import { Link } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
import { UserContext } from "../../Contexts/UseContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Icon } from "@iconify/react";
import { axiosApi } from "../../axiosInstance";

// const Header = ({ data }) => {
//   const { userDetails, setUserDetails } = useContext(UserContext);
//   const { logo } = data;

//   const [mobileToggle, setMobileToggle] = useState(false);
//   const [lastScrollTop, setLastScrollTop] = useState(0);
//   const [isSticky, setIsSticky] = useState(false);
//   const [dropdownVisible, setDropdownVisible] = useState(false);
//   const dropdownRef = useRef(null);

//   const handleMobileToggle = () => {
//     setMobileToggle(!mobileToggle);
//   };

//   const handleLogout = () => {
//     setUserDetails(null);
//     sessionStorage.clear();
//     toast.success("Logout Successfully");
//   };

//   const toggleDropdown = () => {
//     setDropdownVisible(!dropdownVisible);
//   };

//   const handleClickOutside = (event) => {
//     if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
//       setDropdownVisible(false);
//     }
//   };

//   useEffect(() => {
//     document.addEventListener("mousedown", handleClickOutside);

//     return () => {
//       document.removeEventListener("mousedown", handleClickOutside);
//     };
//   }, []);

//   useEffect(() => {
//     const handleScroll = () => {
//       const windowTop = window.scrollY || document.documentElement.scrollTop;

//       if (windowTop >= headerHeight) {
//         setIsSticky(true);
//       } else {
//         setIsSticky(false);
//       }

//       setLastScrollTop(windowTop);
//     };

//     const headerHeight =
//       document.querySelector(".st-sticky-header").offsetHeight + 100;

//     window.addEventListener("scroll", handleScroll);

//     return () => {
//       window.removeEventListener("scroll", handleScroll);
//     };
//   }, [isSticky, lastScrollTop]);

//   return (
//     <header
//       className={`st-site-header st-style1 st-sticky-header ${
//         isSticky ? "st-sticky-active" : ""
//       }`}
//     >
//       {/* <div className={`st-top-header ${userDetails ? "show" : ""}`}>
//         <div className="container st-top-container">
//           <div className="st-top-header-in">
//             <ul className="st-top-header-list">
//               <li>
//                 <ScrollLink to="contact"> info@mydoctorstime.com </ScrollLink>
//               </li>
//               <li>
//                 <ScrollLink to="contact"> +01 - 234 567 890 </ScrollLink>
//               </li>
//             </ul>
//             {userDetails && (
//               <button
//                 style={{
//                   cursor: "pointer",
//                   userSelect: "none",
//                   background: "darkRed",
//                 }}
//                 className="st-site-book-now-btn"
//                 onClick={handleLogout}
//               >
//                 Log out
//               </button>
//             )}
//           </div>
//         </div>
//       </div> */}
//       <div className="st-main-header">
//         <div className="container">
//           <div className="st-main-header-in">
//             <div className="st-main-header-left">
//               <ScrollLink to="home" className="st-site-branding">
//                 <img src={logo} alt="Logo" className="st-site-branding-img" />
//               </ScrollLink>
//             </div>
//             <div className="st-main-header-right">
//               <div className="st-nav">
//                 <ul
//                   className={`st-nav-list st-onepage-nav ${
//                     mobileToggle ? "d-block" : "none"
//                   }`}
//                 >
//                   <li>
//                     <ScrollLink
//                       to="home"
//                       spy={true}
//                       duration={500}
//                       onClick={() => setMobileToggle(false)}
//                     >
//                       Home
//                     </ScrollLink>
//                   </li>
//                   {!userDetails && (
//                     <li>
//                       <Link to={"/doctor-signup"}>For Doctors</Link>
//                     </li>
//                   )}
//                   {!userDetails && (
//                     <li>
//                       <Link to={"/subscription-plan"}>Partner With Us</Link>
//                     </li>
//                   )}
//                   {userDetails && (
//                     <li>
//                       <Link to={"/bookstatus/bookstatus"}>My Appointments</Link>
//                     </li>
//                   )}
//                   {userDetails && (
//                     <li>
//                       <Link>Notifications</Link>
//                     </li>
//                   )}
//                   {userDetails ? (
//                     <li
//                       ref={dropdownRef}
//                       className="log-in-usr-name"
//                       onClick={toggleDropdown}
//                     >
//                       <button
//                         style={{
//                           color: "white",
//                           background: "#006421",
//                           fontWeight: "600",
//                           fontSize: "14px",
//                           paddingLeft: "20px",
//                           paddingRight: "20px",
//                           border: "none",
//                           borderRadius: "4rem",
//                           paddingTop: "13px",
//                           paddingBottom: "13px",
//                           textAlign: "center",
//                         }}
//                       >
//                         <Icon
//                           icon="fa6-solid:user"
//                           className="user-icon"
//                           style={{ marginRight: "6px", marginTop: "-4px" }}
//                         />
//                         Hi,{" "}
//                         {userDetails.user_name
//                           .split("@")[0]
//                           .charAt(0)
//                           .toUpperCase() +
//                           userDetails.user_name.split("@")[0].slice(1)}
//                         !
//                       </button>
//                       {dropdownVisible && (
//                         <>
//                           <div className="user-dropdown-menu">
//                             <li>
//                               <Icon
//                                 icon="fa6-solid:user"
//                                 className="my-profile-icon"
//                                 style={{ marginRight: "16px" }}
//                               />
//                               My Profile
//                             </li>
//                             <li>
//                               <Icon
//                                 icon="fa6-solid:users"
//                                 className="family-icon"
//                                 style={{ marginRight: "10px" }}
//                               />
//                               Add Family
//                             </li>
//                             <li>
//                               <Icon
//                                 icon="fa6-solid:bell"
//                                 className="notification-icon"
//                                 style={{ marginRight: "16px" }}
//                               />
//                               Notification Settings
//                             </li>
//                             <li onClick={handleLogout}>
//                               <Icon
//                                 icon="fa6-solid:arrow-right-from-bracket"
//                                 className="logout-icon"
//                                 style={{ marginRight: "16px" }}
//                               />
//                               Logout
//                             </li>
//                           </div>
//                         </>
//                       )}
//                     </li>
//                   ) : (
//                     <button
//                       style={{
//                         color: "white",
//                         background: "#006421",
//                         fontWeight: "600",
//                         fontSize: "14px",
//                         paddingLeft: "20px",
//                         paddingRight: "20px",
//                         border: "none",
//                         borderRadius: "4rem",
//                       }}
//                     >
//                       <Link to="/patient-login">
//                         {" "}
//                         <Icon
//                           icon="fa6-solid:user"
//                           className="user-icon"
//                           style={{ marginRight: "6px", marginTop: "-4px" }}
//                         />
//                         Sign In
//                       </Link>
//                     </button>
//                   )}
//                 </ul>
//                 <div
//                   style={{ display: "flex", alignItems: "center", gap: "1rem" }}
//                 >
//                   <div
//                     className={`st-munu-toggle ${
//                       mobileToggle ? "st-toggle-active" : ""
//                     }`}
//                     onClick={handleMobileToggle}
//                   >
//                     <span></span>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//       <ToastContainer autoClose={2000} />
//     </header>
//   );
// };

const Header = ({ data }) => {
  const { userDetails, setUserDetails, bookingCompleted, patientId } =
    useContext(UserContext);
  const { logo } = data;

  const userId = userDetails?.user_id;

  const profileDropdownRef = useRef(null);
  const notificationDropdownRef = useRef(null);
  const sidebarRef = useRef(null);

  const [mobileToggle, setMobileToggle] = useState(false);
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [isSticky, setIsSticky] = useState(false);
  const [profileDropdownVisible, setProfileDropdownVisible] = useState(false);
  const [notificationDropdownVisible, setNotificationDropdownVisible] =
    useState(false);

  const [notificationDetails, setNotificationDetails] = useState([]);
  const [unreadNotificationCount, setUnreadNotificationCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [patientDetails, setPatientDetails] = useState([]);

  // const patient_id = bookingCompleted?.newBooking?.patient_id;

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axiosApi.get(
        `/v1/booking/getbookingnotification/${userId}`
      );
      setNotificationDetails(response?.data?.patientnotification || []);
      setUnreadNotificationCount(response?.data?.unreadCount || 0);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const markNotificationsAsRead = async () => {
    setLoading(true);
    try {
      const response = await axiosApi.patch(
        `/v1/booking/markNotificationsAsRead/${userId}`
      );
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [notificationDropdownVisible]);

  const handleMobileToggle = () => {
    setMobileToggle(!mobileToggle);
  };

  const handleLogout = () => {
    setUserDetails(null);
    sessionStorage.clear();
    toast.success("Logout Successfully");
  };

  const toggleProfileDropdown = () => {
    if (notificationDropdownVisible) {
      setNotificationDropdownVisible(false);
    }
    setProfileDropdownVisible(!profileDropdownVisible);
  };

  const toggleNotificationDropdown = () => {
    if (profileDropdownVisible) {
      setProfileDropdownVisible(false);
    }
    setNotificationDropdownVisible(!notificationDropdownVisible);
    markNotificationsAsRead();
  };

  const handleClickOutside = (event) => {
    if (
      profileDropdownRef.current &&
      !profileDropdownRef.current.contains(event.target) &&
      notificationDropdownRef.current &&
      !notificationDropdownRef.current.contains(event.target)
    ) {
      setProfileDropdownVisible(false);
      setNotificationDropdownVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const windowTop = window.scrollY || document.documentElement.scrollTop;

      if (windowTop >= headerHeight) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }

      setLastScrollTop(windowTop);
    };

    const headerHeight =
      document.querySelector(".st-sticky-header").offsetHeight + 100;

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isSticky, lastScrollTop]);

  useEffect(() => {
    const toggleBodyOverflow = () => {
      const body = document.querySelector("body");
      const scrollbarWidth =
        window.innerWidth - document.documentElement.clientWidth;

      if (mobileToggle) {
        body.style.overflow = "hidden";
        body.style.position = "fixed";
        body.style.width = "100%";
        body.style.top = "0";
        body.style.paddingRight = `${scrollbarWidth}px`;
      } else {
        body.style.overflow = "auto";
        body.style.position = "relative";
        body.style.paddingRight = "0";
      }
    };

    toggleBodyOverflow();

    return () => {
      const body = document.querySelector("body");
      body.style.overflow = "auto";
      body.style.position = "relative";
      body.style.paddingRight = "0";
    };
  }, [mobileToggle]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setMobileToggle(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const fetchPatientData = async () => {
    setLoading(true);
    try {
      const response = await axiosApi.get(
        `/v1/patient/getpatient/${patientId}`
      );
      setPatientDetails(response.data?.Patient);
    } catch (e) {
      setLoading(false);
      console.log("error occured", e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPatientData();
  }, [patientId]);

  return (
    <header
      className={`st-site-header st-style1 st-sticky-header ${
        isSticky ? "st-sticky-active" : ""
      }`}
    >
      <div className="st-main-header">
        <div className="container">
          <div className="st-main-header-in">
            <div
              className={`st-munu-toggle ${
                mobileToggle ? "st-toggle-active" : ""
              }`}
              onClick={handleMobileToggle}
            >
              <span></span>
            </div>

            <div className="st-main-header-left">
              <ScrollLink to="home" className="st-site-branding">
                <img src={logo} alt="Logo" className="st-site-branding-img" />
              </ScrollLink>
            </div>

            <div className="st-main-header-right">
              <div className="st-nav">
                <ul className="st-nav-list st-onepage-nav">
                  <li>
                    <ScrollLink
                      to="home"
                      spy={true}
                      duration={500}
                      onClick={() => setMobileToggle(false)}
                    >
                      Home
                    </ScrollLink>
                  </li>

                  {/* {!userDetails && (
                    <li>
                      <Link to={"/doctor-signup"}>For Doctors</Link>
                    </li>
                  )} */}

                  {!userDetails && (
                    <li>
                      <Link to={"/partner-with-us"}>Partner With Us</Link>
                    </li>
                  )}

                  {userDetails && (
                    <li>
                      <Link to={"/bookstatus/bookstatus"}>My Appointments</Link>
                    </li>
                  )}

                  {userDetails && (
                    <li
                      className="notification-menu"
                      onClick={() => {
                        // markNotificationsAsRead();
                        toggleNotificationDropdown();
                      }}
                      ref={notificationDropdownRef}
                    >
                      <span
                        style={{
                          color: "#666",
                          fontWeight: "600",
                          fontSize: "14px",
                          display: "flex",
                          gap: "5px",
                        }}
                      >
                        Notifications
                        <span>
                          {unreadNotificationCount > 0 ? (
                            <span
                              style={{
                                display: "inline-flex",
                                alignItems: "center",
                                justifyContent: "center",
                                width: "23px",
                                height: "22px",
                                borderRadius: "50%",
                                backgroundColor: "green",
                                color: "white",
                                fontSize: "12px",
                                fontWeight: "bold",
                              }}
                            >
                              {unreadNotificationCount}
                            </span>
                          ) : (
                            ""
                          )}
                        </span>
                      </span>
                      {notificationDropdownVisible && (
                        <div className="notification-dropdown">
                          <div className="notification-dropdown-header">
                            Notifications
                          </div>
                          {notificationDetails.length > 0 ? (
                            notificationDetails.map(
                              (notification, index) =>
                                notification.message && (
                                  <li
                                    key={index}
                                    className="notifications-page-listing"
                                  >
                                    <div className="notifications-page-listing-details">
                                      <img
                                        src="https://t3.ftcdn.net/jpg/05/14/36/48/360_F_514364850_xLOQX6SOY2qcjAIcTowsi3xYvHmhmvs0.jpg"
                                        style={{
                                          height: "30px",
                                        }}
                                        alt="Notification Icon"
                                      />
                                      {notification.message}
                                    </div>
                                  </li>
                                )
                            )
                          ) : (
                            <div
                              style={{ textAlign: "center", marginTop: "50px" }}
                            >
                              <p>No notifications available</p>
                            </div>
                          )}
                        </div>
                      )}
                    </li>
                  )}

                  {userDetails ? (
                    <li
                      className="log-in-usr-name"
                      onClick={() => {
                        toggleProfileDropdown();
                      }}
                      ref={profileDropdownRef}
                    >
                      <span
                        style={{
                          color: "#006421",
                          fontWeight: "600",
                          fontSize: "14px",
                        }}
                      >
                        <Icon
                          icon="fa6-solid:user"
                          className="user-icon"
                          style={{
                            marginRight: "6px",
                            marginTop: "-3px",
                            border: "1px solid",
                            borderRadius: "50%",
                            width: "15px",
                            height: "15px",
                            padding: "1px",
                          }}
                        />
                        Hi,{" "}
                        {/* {userDetails.user_name
                          .split("@")[0]
                          .charAt(0)
                          .toUpperCase() +
                          userDetails.user_name.split("@")[0].slice(1)} */}
                        {patientDetails.name}!
                      </span>
                      {profileDropdownVisible && (
                        <div className="user-dropdown-menu">
                          <div
                            className="user-dropdown-menu-list"
                            onClick={(e) => e.stopPropagation()}
                          >
                            <Link to={"/myProfile"}>
                              <Icon
                                icon="fa6-solid:user"
                                className="my-profile-icon"
                                style={{ marginRight: "16px" }}
                              />
                              My Profile
                            </Link>
                          </div>
                          <div
                            className="user-dropdown-menu-list"
                            onClick={(e) => e.stopPropagation()}
                          >
                            <Link to="/addfamily">
                              <Icon
                                icon="fa6-solid:users"
                                className="family-icon"
                                style={{ marginRight: "10px" }}
                              />
                              Add Family
                            </Link>
                          </div>
                          <div
                            onClick={(e) => {
                              e.stopPropagation();
                              handleLogout();
                            }}
                            className="user-dropdown-menu-list"
                          >
                            <Icon
                              icon="fa6-solid:arrow-right-from-bracket"
                              className="logout-icon"
                              style={{ marginRight: "16px" }}
                            />
                            Logout
                          </div>
                        </div>
                      )}
                    </li>
                  ) : (
                    <span
                      style={{
                        color: "#006421",
                        fontWeight: "600",
                        fontSize: "14px",
                        marginTop: "14px",
                      }}
                    >
                      <Link to="/patient-login">
                        <Icon
                          icon="fa6-solid:user"
                          className="user-icon"
                          style={{
                            marginRight: "6px",
                            marginTop: "-2px",
                            border: "1px solid",
                            borderRadius: "50%",
                            width: "15px",
                            height: "15px",
                            padding: "1px",
                          }}
                        />
                        Login
                      </Link>
                    </span>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      {userDetails ? (
        <div
          className={`st-header-sidebar ${
            mobileToggle ? "show-st-header-sidebar" : ""
          }`}
          ref={sidebarRef}
        >
          <div className="st-header-sidebar-st-head">
            <img
              src={logo}
              alt="Logo"
              className="st-site-branding-img-sidebar"
            />
            <div
              className="st-header-sidebar-close-btn"
              onClick={handleMobileToggle}
            >
              &times;
            </div>
          </div>
          <div className="st-header-sidebar-pad">
            <Link to={"/myProfile"} style={{ display: "flex" }}>
              <div className="st-sidebar-menu-item">
                <Icon
                  icon="fa6-solid:user"
                  className="family-icon"
                  style={{ marginRight: "15px", marginTop: "2px" }}
                />
                <p>My Profile</p>
              </div>
            </Link>

            <Link to="/addfamily" style={{ display: "flex" }}>
              <div className="st-sidebar-menu-item">
                <Icon
                  icon="fa6-solid:users"
                  className="family-icon"
                  style={{ marginRight: "10px", marginTop: "2px" }}
                />
                <p>Add Family</p>
              </div>
            </Link>

            {/* <div className="st-sidebar-menu-item">
              <Icon
                icon="fa6-solid:bell"
                className="family-icon"
                style={{ marginRight: "15px", marginTop: "2px" }}
              />
              <p>Notification Settings</p>
            </div> */}

            <Link to={"/bookstatus/bookstatus"} style={{ display: "flex" }}>
              <div className="st-sidebar-menu-item">
                <Icon
                  icon="fa6-solid:bell"
                  className="family-icon"
                  style={{ marginRight: "15px", marginTop: "2px" }}
                />
                <p>My Appointments</p>
              </div>
            </Link>

            <Link to={"/notifications"} style={{ display: "flex" }}>
              <div className="st-sidebar-menu-item">
                <Icon
                  icon="fa6-solid:bell"
                  className="family-icon"
                  style={{ marginRight: "15px", marginTop: "2px" }}
                />
                <p>Notifications</p>
                <span>
                  {unreadNotificationCount > 0 ? (
                    <span
                      style={{
                        display: "inline-flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "23px",
                        height: "22px",
                        borderRadius: "50%",
                        backgroundColor: "green",
                        color: "white",
                        fontSize: "12px",
                        fontWeight: "bold",
                        marginLeft: "5px",
                      }}
                    >
                      {unreadNotificationCount}
                    </span>
                  ) : (
                    ""
                  )}
                </span>
              </div>
            </Link>

            <div
              className="st-sidebar-menu-item"
              onClick={() => {
                handleLogout();
                handleMobileToggle();
              }}
            >
              <Icon
                icon="fa6-solid:arrow-right-from-bracket"
                className="family-icon"
                style={{ marginRight: "15px", marginTop: "2px" }}
              />
              <p>Logout</p>
            </div>
          </div>
        </div>
      ) : (
        <div
          className={`st-header-sidebar ${
            mobileToggle ? "show-st-header-sidebar" : ""
          }`}
          ref={sidebarRef}
        >
          <div className="st-header-sidebar-st-head">
            <img
              src={logo}
              alt="Logo"
              className="st-site-branding-img-sidebar"
            />
            <div
              className="st-header-sidebar-close-btn"
              onClick={handleMobileToggle}
            >
              &times;
            </div>
          </div>
          <div className="st-header-sidebar-pad">
            <ScrollLink
              to="home"
              spy={true}
              duration={500}
              onClick={() => setMobileToggle(false)}
            >
              <div className="st-sidebar-menu-item">
                <Icon
                  icon="material-symbols:home"
                  className="family-icon"
                  style={{ marginRight: "16px", marginTop: "2px" }}
                />
                <p>Home</p>
              </div>
            </ScrollLink>
            <Link to={"/partner-with-us"}>
              <div className="st-sidebar-menu-item">
                <Icon
                  icon="fa-solid:handshake"
                  className="family-icon"
                  style={{ marginRight: "10px", marginTop: "2px" }}
                />
                <p>Partner With Us</p>
              </div>
            </Link>
            <Link to="/patient-login">
              <div className="st-sidebar-menu-item">
                <Icon
                  icon="fa6-solid:user"
                  className="user-icon"
                  style={{ marginRight: "16px" }}
                />
                Login
              </div>
            </Link>
          </div>
        </div>
      )}

      <ToastContainer autoClose={2000} />
    </header>
  );
};

export default Header;
