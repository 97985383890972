import React, { useContext, useEffect, useState } from "react";
import Spacing from "../../Components/Spacing/Spacing";
import Eye from "../../Components/PasswordEye/Eye";
import CloseEye from "../../Components/PasswordEye/CloseEye";
import { axiosApi } from "../../axiosInstance";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../Contexts/UseContext";
import { formatDate } from "../../utils/formatDate";
import PhoneNumberInput from "../../Components/PhoneNumberInput/PhoneNumberInput";

// const PatientRegister = () => {
//   const [showPassword, setShowPassword] = useState(false);
//   const [showConfirmPassword, setShowConfirmPassword] = useState(false);
//   const [input, setInput] = useState({
//     user_name: "",
//     password: "",
//     confirmPassword: "",
//   });

//   const [errors, setErrors] = useState({});
//   const [loading, setLoading] = useState(false);
//   const navigate = useNavigate();
//   const { setPageTitle, setUserDetails } = useContext(UserContext);

//   useEffect(() => {
//     setPageTitle("Patient Register");
//   }, []);

//   const validate = () => {
//     const newErrors = {};
//     if (input.password !== input.confirmPassword) {
//       newErrors.confirmPassword = "Passwords do not match";
//     }
//     if(input.password.length < 6) {
//       newErrors.password = "Password should contain minimum 6 characters";
//       toast.warning("Password should contain minimum 6 characters");
//     }
//     setErrors(newErrors);
//     return Object.keys(newErrors).length === 0;
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     if (!input.user_name || !input.password) {
//       return;
//     }

//     if (validate()) {
//       setLoading(true);

//       try {
//         const data = { ...input };
//         const response = await axiosApi.post(
//           "/v1/patient/registerpatient",
//           data
//         );

//         if (response.status === 201) {
//           const { accessToken, refreshToken, user } = response.data;

//           toast.success("Signup successful!");
//           setInput({
//             user_name: "",
//             password: "",
//             confirmPassword: "",
//           });

//           setTimeout(() => {
//             navigate("/patient-login");
//           }, 3000);
//         } else {
//           toast.error("Signup failed. Please try again.");
//         }
//       } catch (error) {
//         if (error?.response?.status === 403) {
//           toast.error(error?.response?.data?.error || "User already exist");
//         } else {
//           toast.error("Signup error. Please try again.");
//         }
//       } finally {
//         setLoading(false);
//       }
//     }
//   };

//   const handlePasswordChange = (e) => {
//     const value = e.target.value;
//     setInput({...input, password: value});
//     if(value >= 6){
//       setErrors((prevErrors) => ({...prevErrors, password: ""}));
//     }
//     if(value === input?.confirmPassword){
//       setErrors((prevErrors) => ({...prevErrors, confirmPassword:""}));
//     }
//   }

//   const handleConfirmPasswordChange = (e) => {
//     const value = e.target.value;
//     setInput({...input, confirmPassword: value});
//     if(value === input?.password){
//       setErrors((prevErrors) => ({...prevErrors, confirmPassword:""}));
//     }
//   }

//   return (
//     <>
//       <Spacing lg={120} md={60} />
//       <div className="container mt-5">
//         <div className="booking_container patient_login_container">
//           <div className="booking_form_card">
//             <form onSubmit={handleSubmit}>
//               <div className="patient_details_wrapper patient_details_form_wrapper">
//                 <div className="patient_login_card_header">
//                   <p className="booking_confirmation_card_title">Signup</p>
//                 </div>
//                 <Spacing lg={35} md={20} />
//                 <label htmlFor="user_name" className="form-label mb-2 mt-2">
//                   Email
//                 </label>
//                 <div className="mb-2">
//                   <input
//                     type="email"
//                     className="form-control"
//                     id="user_name"
//                     placeholder="Email"
//                     required
//                     value={input.user_name}
//                     onChange={(e) =>
//                       setInput({ ...input, user_name: e.target.value })
//                     }
//                   />
//                 </div>
//                 <label htmlFor="password" className="form-label mb-2 mt-2">
//                   Password
//                 </label>
//                 <div className="password-input-container mb-2">
//                   <input
//                     type={showPassword ? "text" : "password"}
//                     className="form-control"
//                     id="password"
//                     value={input.password}
//                     placeholder="Password"
//                     required
//                     onChange={handlePasswordChange}
//                   />
//                   <div
//                     className="icon-container"
//                     onClick={() => setShowPassword(!showPassword)}
//                   >
//                     {showPassword ? <Eye /> : <CloseEye />}
//                   </div>
//                 </div>
//                 {errors.password && (
//                   <p className="text-danger">
//                     {errors.password}
//                   </p>
//                 )}
//                 <label
//                   htmlFor="confirm_password"
//                   className="form-label mb-2 mt-2"
//                 >
//                   Confirm Password
//                 </label>
//                 <div className="password-input-container">
//                   <input
//                     type={showConfirmPassword ? "text" : "password"}
//                     className="form-control"
//                     id="confirm_password"
//                     value={input.confirmPassword}
//                     placeholder="Confirm Password"
//                     required
//                     onChange={handleConfirmPasswordChange}
//                   />
//                   <div
//                     className="icon-container"
//                     onClick={() => setShowConfirmPassword(!showConfirmPassword)}
//                   >
//                     {showConfirmPassword ? <Eye /> : <CloseEye />}
//                   </div>
//                 </div>
//                 {errors.confirmPassword && (
//                   <p className="text-danger">{errors.confirmPassword}</p>
//                 )}
//                 <Spacing lg={40} md={30} />
//                 <div className="patient_login_btn_wrapper">
//                   <button
//                     className="booking_form_card_btn"
//                     style={{ minWidth: "13rem", height: "2.75rem" }}
//                     disabled={loading}
//                   >
//                     {loading ? <span className="loader"></span> : "Signup"}
//                   </button>
//                 </div>
//               </div>
//             </form>
//           </div>
//         </div>
//       </div>
//       <Spacing lg={120} md={80} />
//       <ToastContainer position="top-center" autoClose={2000} />
//     </>
//   );
// };

const PatientRegister = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [input, setInput] = useState({
    name: "",
    phone: "",
    dateOfBirth: "",
    gender: "",
    user_name: "",
    password: "",
    confirmPassword: "",
  });

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { setPageTitle, setUserDetails } = useContext(UserContext);

  useEffect(() => {
    setPageTitle("Patient Register");
  }, []);

  const validate = () => {
    const email = input.user_name;
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const newErrors = {};
    let errorMessages = [];
    if (input.password !== input.confirmPassword) {
      newErrors.confirmPassword = "Passwords do not match";
    }
    if (input.password.length < 6) {
      newErrors.password = "Password should contain minimum 6 characters";
      toast.warning("Password should contain minimum 6 characters");
    }
    if (!emailRegex.test(email)) {
      newErrors.email = "Enter a valid email address";
      errorMessages.push("Enter a valid email address");
    }
    if (errorMessages.length > 0) {
      toast.warning(errorMessages[0]); // Show only the first error
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      !input.user_name ||
      !input.password ||
      !input.name ||
      !input.phone ||
      !input.dateOfBirth ||
      !input.gender
    ) {
      return;
    }

    if (validate()) {
      setLoading(true);

      try {
        const data = { ...input, phone: `+91${input.phone}` };
        const response = await axiosApi.post(
          "/v1/patient/registerpatient",
          data
        );

        if (response.status === 201) {
          const { accessToken, refreshToken, user } = response.data;

          toast.success("Signup successful!");
          setInput({
            name: "",
            phone: "",
            dateOfBirth: "",
            gender: "",
            user_name: "",
            password: "",
            confirmPassword: "",
          });

          setTimeout(() => {
            navigate("/patient-login");
          }, 3000);
        } else {
          toast.error("Signup failed. Please try again.");
        }
      } catch (error) {
        if (error?.response?.status === 403) {
          toast.error(error?.response?.data?.error || "User already exist");
        } else {
          toast.error("Signup error. Please try again.");
        }
      } finally {
        setLoading(false);
      }
    }
  };

  const handlePasswordChange = (e) => {
    const value = e.target.value;
    setInput({ ...input, password: value });
    if (value >= 6) {
      setErrors((prevErrors) => ({ ...prevErrors, password: "" }));
    }
    if (value === input?.confirmPassword) {
      setErrors((prevErrors) => ({ ...prevErrors, confirmPassword: "" }));
    }
  };

  const handleConfirmPasswordChange = (e) => {
    const value = e.target.value;
    setInput({ ...input, confirmPassword: value });
    if (value === input?.password) {
      setErrors((prevErrors) => ({ ...prevErrors, confirmPassword: "" }));
    }
  };

  const handlePhoneChange = (value) => {
    setInput({ ...input, phone: value });
    if (value.length === 10) {
      setErrors((prevErrors) => ({ ...prevErrors, phone: "" }));
    }
  };

  const currentDate = formatDate(new Date());

  return (
    <>
      <Spacing lg={60} md={60} />
      <div className="container mt-5">
        <div className="booking_container patient_login_container">
          <div className="booking_form_card">
            <form onSubmit={handleSubmit}>
              <div className="patient_details_wrapper patient_details_form_wrapper">
                <div className="patient_login_card_header">
                  <p className="booking_confirmation_card_title">
                    Create your account
                  </p>
                </div>
                <Spacing lg={20} md={20} />
                <div className="mb-3">
                  <label htmlFor="dob" className="form-label mb-0">
                    Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    // placeholder="Name"
                    value={input?.name}
                    onChange={(e) =>
                      setInput({ ...input, name: e.target.value })
                    }
                    required
                  />
                </div>

                <label htmlFor="dob" className="form-label mb-0">
                  Phone
                </label>
                <PhoneNumberInput
                  value={input?.phone}
                  onChange={handlePhoneChange}
                  error={errors?.phone}
                  maxLength="10"
                />
                <div className="row g-2 mb-3 mt-2">
                  <div className="col-md-6">
                    <label htmlFor="dob" className="form-label mb-0">
                      Date of Birth
                    </label>
                    <input
                      id="dob"
                      type="date"
                      className="form-control"
                      placeholder="DOB"
                      style={{ paddingRight: "10px" }}
                      value={input?.dateOfBirth}
                      onChange={(e) =>
                        setInput({ ...input, dateOfBirth: e.target.value })
                      }
                      max={currentDate}
                      required
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="gender" className="form-label mb-0">
                      Gender
                    </label>
                    <select
                      id="gender"
                      className="form-select form-control"
                      aria-label="Default select example"
                      value={input?.gender}
                      onChange={(e) =>
                        setInput({ ...input, gender: e.target.value })
                      }
                      required
                    >
                      <option value="">Select Gender</option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                      <option value="Other">Other</option>
                    </select>
                  </div>
                </div>
                <label htmlFor="user_name" className="form-label mb-2">
                  Email
                </label>
                <div className="mb-2">
                  <input
                    type="email"
                    className="form-control"
                    id="user_name"
                    required
                    value={input.user_name}
                    onChange={(e) =>
                      setInput({ ...input, user_name: e.target.value })
                    }
                  />
                </div>

                <div className="row g-2 mb-3 mt-2">
                  <div className="col-md-6">
                    <label htmlFor="dob" className="form-label mb-0">
                      Password
                    </label>
                    <div className="password-input-container mb-2">
                      <input
                        type={showPassword ? "text" : "password"}
                        className="form-control"
                        id="password"
                        value={input.password}
                        required
                        onChange={handlePasswordChange}
                      />
                      <div
                        className="icon-container"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? <Eye /> : <CloseEye />}
                      </div>
                    </div>
                    {errors.password && (
                      <p className="text-danger">{errors.password}</p>
                    )}
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="gender" className="form-label mb-0">
                      Confirm Password
                    </label>
                    <div className="password-input-container">
                      <input
                        type={showConfirmPassword ? "text" : "password"}
                        className="form-control"
                        id="confirm_password"
                        value={input.confirmPassword}
                        required
                        onChange={handleConfirmPasswordChange}
                      />
                      <div
                        className="icon-container"
                        onClick={() =>
                          setShowConfirmPassword(!showConfirmPassword)
                        }
                      >
                        {showConfirmPassword ? <Eye /> : <CloseEye />}
                      </div>
                    </div>
                    {errors.confirmPassword && (
                      <p className="text-danger">{errors.confirmPassword}</p>
                    )}
                  </div>
                </div>
                <Spacing lg={15} md={30} />
                <div className="patient_login_btn_wrapper">
                  <button
                    className="booking_form_card_btn"
                    style={{ minWidth: "13rem", height: "2.75rem" }}
                    disabled={loading}
                  >
                    {loading ? <span className="loader"></span> : "Signup"}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Spacing lg={80} md={80} />
      <ToastContainer position="top-center" autoClose={2000} />
    </>
  );
};

export default PatientRegister;
