import React, { useContext, useEffect, useState } from "react";
import { axiosApi } from "../../axiosInstance";
import { UserContext } from "../../Contexts/UseContext";
import { reverseFormatDate } from "../../utils/formatDate";
import { formatTime } from "../../utils/FormatTime";

const NotificationsPage = () => {
  const { setPageTitle, userDetails, bookingCompleted } =
    useContext(UserContext);

  useEffect(() => {
    setPageTitle("Notifications");
  }, []);

  const [notificationDetails, setNotificationDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [unreadNotificationCount, setUnreadNotificationCount] = useState(0);

  const patient_id = bookingCompleted?.newBooking?.patient_id;

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axiosApi.get(
        `/v1/booking/getbookingnotification/${patient_id}`
      );
      setNotificationDetails(response?.data?.patientnotification || []);
      setUnreadNotificationCount(response?.data?.unreadCount || 0);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const markNotificationsAsRead = async () => {
      setLoading(true);
      try {
        const response = await axiosApi.patch(
          `/v1/booking/markNotificationsAsRead/${patient_id}`
        );
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    };

  useEffect(() => {
    fetchData();
    markNotificationsAsRead();
  }, [patient_id]);

  return (
    <div>
      <div className="st-height-b120 st-height-lg-b80" />
      <div className="container">
        {loading ? (
          <div className="custom-loader_container">
            <span className="custom-loader"></span>
          </div>
        ) : (
          <div className="details_wrapper">
            <div style={{ width: "100%", minHeight: "400px" }}>
              <div className="doctor_name notifications-page-headers">
                Notifications
              </div>
              <div>
                {notificationDetails.length > 0 ? (
                  notificationDetails.map(
                    (notification, index) =>
                      notification.message && (
                        <li key={index} className="notifications-page-listing">
                          <div className="notifications-page-listing-details">
                            <img
                              src="https://t3.ftcdn.net/jpg/05/14/36/48/360_F_514364850_xLOQX6SOY2qcjAIcTowsi3xYvHmhmvs0.jpg"
                              style={{
                                height: "30px",
                              }}
                              alt="Notification Icon"
                            />
                            {notification.message}
                          </div>
                        </li>
                      )
                  )
                ) : (
                  <div className="empty_data">
                    <img
                      src="https://t3.ftcdn.net/jpg/06/33/20/34/360_F_633203457_nPxX7CBjaxzCL2AxgRc1TMt2l4IikgoB.jpg"
                      alt="No Data"
                      style={{
                        width: "100px",
                        height: "100px",
                      }}
                    />
                    <p>No notifications available</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="st-height-b120 st-height-lg-b90" />
    </div>
  );
};

export default NotificationsPage;
