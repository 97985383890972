import React, { useContext, useEffect, useState } from "react";
import SectionHeading from "../../Components/SectionHeading/SectionHeading";
import { Link } from "react-router-dom";
import { UserContext } from "../../Contexts/UseContext";
import { axiosApi, imageBase_URL } from "../../axiosInstance";

const AllSpecializations = () => {
  const { setPageTitle } = useContext(UserContext);

  useEffect(() => {
    setPageTitle("All Specializations");
  }, []);

  const [loading, setLoading] = useState(false);
  const [availableSpecializations, setAvailableSpecializations] = useState([]);
  const [page, setPage] = useState(1);
  const pageSize = 6;

  function encodeSpecializationId(specializationId) {
    return btoa(specializationId.toString());
  }

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axiosApi.get(
        `/v1/specialization/specializationsByDoctors?pagesize=${pageSize}&page=${page}`
      );
      setAvailableSpecializations(response?.data?.specializations);
    } catch (e) {
      setLoading(false);
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [page, pageSize]);

  const classNames = [
    "st-blue-box",
    "st-red-box",
    "st-green-box",
    "st-dip-blue-box",
    "st-orange-box",
    "st-gray-box",
  ];

  return (
    <section id="department">
      <div className="st-height-b120 st-height-lg-b80" />
      <SectionHeading title="Specializations" />
      <div className="container">
        {loading ? (
          <div className="custom-loader_container">
            <span className="custom-loader"></span>
          </div>
        ) : (
          <div className="st-tabs st-fade-tabs st-style1">
            <ul className="st-tab-specializations st-style1 st-mp0">
              {availableSpecializations.map((specialization, index) => (
                <Link
                  key={specialization.id}
                  to={`/doctor-specializations/${encodeSpecializationId(
                    specialization.id
                  )}`}
                >
                  <li key={specialization.id}>
                    <span
                      className={`${
                        classNames[index % classNames.length]
                      } st-tab-specializations-container st-tab-title`}
                    >
                      <img
                        style={{ width: "100px", height: "100px" }}
                        src={imageBase_URL + specialization.specialization_icon}
                      />
                      <span>{specialization.name}</span>
                    </span>
                  </li>
                </Link>
              ))}
            </ul>
            <div className="st-height-b25 st-height-lg-b25" />
          </div>
        )}
      </div>
      <div className="st-height-b120 st-height-lg-b80" />
    </section>
  );
};

export default AllSpecializations;
