import { React, useContext, useEffect, useRef, useState } from "react";
import Social from "../Social/Social";
import HeroSlider from "../Slider/HeroSlider";
import { Link as ScrollLink } from "react-scroll";
import TextTransition, { presets } from "react-text-transition";
import { Link } from "react-router-dom";
import { UserContext } from "../../Contexts/UseContext";
import { Icon } from "@iconify/react/dist/iconify.js";
import { axiosApi } from "../../axiosInstance";

const Hero = ({ data }) => {
  const { userDetails } = useContext(UserContext);

  const bgImg = data.bgImg;
  const bgShape = data.bgShape;
  const sliderImages = data.sliderImages;
  const title = data.title;

  const [index, setIndex] = useState(0);
  useEffect(() => {
    const intervalId = setInterval(
      () => setIndex((index) => index + 1),
      3000 // every 3 seconds
    );
    return () => clearTimeout(intervalId);
  }, []);

  function encodeDoctorId(doctorId) {
    return btoa(doctorId.toString());
  }

  function encodeClinicId(clinicId) {
    return btoa(clinicId.toString());
  }

  function encodeSpecializationId(specializationId) {
    return btoa(specializationId.toString());
  }

  const [loading, setLoading] = useState(false);
  const [filteredDoctors, setFilteredDoctors] = useState([]);
  const [page, setPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [locationQuery, setLocationQuery] = useState("");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const [searchResultsOpen, setSearchResultsOpen] = useState(false);
  const searchSectionRef = useRef(null);
  const pageSize = 20;
  const [useMylocation, setUseMyLocation] = useState(null);
  const [error, setError] = useState(null);
  const [locationText, setLocationText] = useState("Search Location");
  const [suggestions, setSuggestions] = useState([]);
  const [searchSuggestions, setSearchSuggestions] = useState([]);

  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    if (query.length > 0) {
      fetchSuggestions(query);
      setSearchResultsOpen(true);
    } else {
      setSearchSuggestions({ doctors: [], clinics: [], specializations: [] });
      setSearchResultsOpen(false);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Check if the click target is outside the dropdown area
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false); // Close the dropdown
      }
    };

    // Add event listener
    document.addEventListener("click", handleClickOutside);

    // Cleanup event listener
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleOutsideClick = (e) => {
    if (
      searchSectionRef.current &&
      !searchSectionRef.current.contains(e.target)
    ) {
      setSearchResultsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const handlePageClick = (event) => {
    setPage(event.selected + 1);
  };

  // Toggle dropdown visibility
  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  useEffect(() => {
    if (locationQuery) {
      setLocationText(locationQuery); // Show the current query if available
    } else {
      setLocationText("Search Location"); // Default text when no query
    }
  }, [locationQuery]);

  const getSuggestions = async (query) => {
    try {
      const response = await axiosApi.get(
        `/v1/suggestion/suggestLocations?query=${query}`
      );
      if (query.length > 0) {
        setSuggestions(response.data?.suggestions || []);
      } else {
        setSuggestions([]);
      }
    } catch (e) {
      console.error("Error fetching suggestions:", error);
      setSuggestions([]);
    }
  };

  const fetchSuggestions = async (keyword) => {
    setLoading(true);
    try {
      const response = await axiosApi.get(
        `/v1/suggestion/suggestSearch?keyword=${keyword}`
      );
      setSearchSuggestions(response?.data?.suggestions);
      console.log(response?.data?.suggestions);
    } catch (e) {
      setLoading(false);
      console.log("Error Occured", e);
    } finally {
      setLoading(false);
    }
  };

  const handleLocationChange = (e) => {
    const query = e.target.value;
    setLocationQuery(query);
    if (query.length > 0) {
      getSuggestions(query);
    } else {
      setSuggestions([]);
    }
  };

  const handleSuggestionClick = (suggestion) => {
    setLocationQuery(suggestion);
    setSuggestions([]);
    setDropdownOpen(false);
  };

  const getMyLocation = async () => {
    setLoading(true);
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;
          const location = { latitude, longitude };

          setUseMyLocation(location);

          try {
            const googlelocation = `{"lat":${latitude},"long":${longitude}}`;
            const response = await axiosApi.get(
              `/v1/doctor/getalldoctorcurrentloc?pageSize=${pageSize}&page=${page}&googlelocation=${googlelocation}`
            );
            setFilteredDoctors(response?.data?.Doctors);
            setLocationText("My Current location");
          } catch (e) {
            console.log("Error", e);
          } finally {
            setLoading(false);
          }

          setError(null);
        },
        (err) => {
          switch (err.code) {
            case err.PERMISSION_DENIED:
              setError("User denied the request for Geolocation.");
              break;
            case err.POSITION_UNAVAILABLE:
              setError("Location information is unavailable.");
              break;
            case err.TIMEOUT:
              setError("The request to get user location timed out.");
              break;
            default:
              setError("An unknown error occurred.");
              break;
          }
        }
      );
    } else {
      setError("Geolocation is not supported by this browser.");
    }
  };

  return (
    <div
      id="home"
      className="st-hero-wrap st-gray-bg st-dynamic-bg st-fixed-bg"
      style={{ backgroundImage: `url(${bgImg})` }}
    >
      <div className="st-hero st-style1">
        <div className="container">
          <div className="st-hero-text">
            <div className="st-height-b40 st-height-lg-b40" />
            <h1 className="st-hero-title cd-headline slide">
              Find the perfect doctor
            </h1>
            <div className="st-hero-subtitle">
              Your one-stop platform for convenient healthcare. Book
              appointments
              <br />
              in minutes with{" "}
              <span style={{ fontWeight: "700" }}> mydoctorstime</span>
            </div>

            {/* <div className="st-hero-btn-container">
              <div className="st-hero-btn">
                {userDetails ? (
                  <ScrollLink
                    to="doctors"
                    className="st-btn st-style1 st-color1 st-smooth-move"
                  >
                    {" "}
                    Book Appointment{" "}
                  </ScrollLink>
                ) : (
                  <Link
                    to="/patient-login"
                    className="st-btn st-style1 st-color1 st-smooth-move"
                  >
                    {" "}
                    Book Appointment{" "}
                  </Link>
                )}
              </div>
              <div className="st-hero-btn ">
                <Link
                  to={userDetails ? "/BookStatus/BookStatus" : "/patient-login"}
                  className="st-btn st-style1 st-color1 st-smooth-move st-hero-outline-btn"
                >
                  {" "}
                  Track Appointment{" "}
                </Link>
              </div>
            </div> */}
          </div>

          <div
            className="search_container"
            style={{ marginTop: "2.5rem", position: "relative" }}
          >
            <div
              className="location-search-container h-9 divide-x"
              style={{ display: "flex" }}
            >
              <div
                className="location_section"
                onClick={toggleDropdown}
                ref={dropdownRef}
                role="button"
              >
                <div
                  style={{ display: "flex", alignItems: "center", gap: "5px" }}
                >
                  <Icon
                    icon="fa6-solid:location-dot"
                    className="location_icon"
                  />
                  <div className="location_text"> {locationText} </div>
                  <Icon
                    icon="fa6-solid:angle-down"
                    className="down_arrow_icon"
                  />
                </div>
                {dropdownOpen && (
                  <div
                    id="locationDropdown"
                    // className={`dropdown_container ${
                    //   suggestions.length > 0 ? "has-suggestions" : ""
                    // }`}
                    className="dropdown_container"
                  >
                    <div className="locationDropdown-image-intput">
                      <img
                        src={`${process.env.PUBLIC_URL}/icons/search_icon.svg`}
                        alt="search"
                        className="search_icon"
                        style={{ marginTop: "7px" }}
                      />
                      <input
                        type="text"
                        className="dropdown_input"
                        placeholder="Search Location"
                        value={locationQuery}
                        onClick={(e) => e.stopPropagation()}
                        onChange={handleLocationChange}
                      />
                    </div>
                    <div
                      className="dropdown_option"
                      style={{ display: "flex", gap: "5px" }}
                      onClick={getMyLocation}
                    >
                      <Icon
                        icon="fa6-solid:location-dot"
                        className="location_icon"
                      />
                      <div>Use my location</div>
                    </div>
                    {suggestions.length > 0 && (
                      <div className="suggestions_list">
                        {suggestions.map((suggestion, index) => (
                          <div
                            key={index}
                            className="dropdown_option"
                            onClick={() => handleSuggestionClick(suggestion)}
                          >
                            {suggestion}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                )}
              </div>

              <div className="search_section" ref={searchSectionRef}>
                <img
                  src={`${process.env.PUBLIC_URL}/icons/search_icon.svg`}
                  alt="searchIcon"
                  className="search_icon"
                />
                <input
                  type="text"
                  className="search_input"
                  placeholder="Search for Doctors, Hospitals & Specializations"
                  value={searchQuery}
                  onChange={handleSearchChange}
                  onFocus={() => setSearchResultsOpen(searchQuery.length > 0)}
                />
              </div>
            </div>

            {searchResultsOpen && (
              <div className="banner_search_result_list_container">
                <ul className="banner_search_result_list">
                  {searchSuggestions?.doctors?.map((doctor) => (
                    <Link
                      key={doctor.doctor_id}
                      to={`/doctor-profile/${encodeDoctorId(doctor.doctor_id)}`}
                    >
                      <li key={doctor.doctor_id} className="list_item">
                        <h3 className="name_text">{doctor.name}</h3>
                        <p className="designation_text">
                          {doctor.specialization}
                        </p>
                      </li>
                    </Link>
                  ))}
                  {searchSuggestions?.clinics?.map((clinic) => (
                    <Link
                      key={clinic.clinic_id}
                      to={`/clinic/${encodeClinicId(clinic.clinic_id)}`}
                    >
                      <li key={clinic.clinic_id} className="list_item">
                        <h3 className="name_text">{clinic.name}</h3>
                      </li>
                    </Link>
                  ))}
                  {searchSuggestions?.specializations?.map((specialization) => (
                    <Link
                      key={specialization.specialization_id}
                      to={`/doctor-specializations/${encodeSpecializationId(
                        specialization.specialization_id
                      )}`}
                    >
                      <li
                        key={specialization.specialization_id}
                        className="list_item"
                      >
                        <h3 className="name_text">{specialization.name}</h3>
                      </li>
                    </Link>
                  ))}
                  {/* <li className="list_item">
                    <h3 className="name_text">Dr. Nikhil manakkadan</h3>
                    <p className="designation_text">
                      Consultant interanl medicine
                    </p>
                  </li>{" "}
                  <li className="list_item">
                    <h3 className="name_text">Dr. Nikhil manakkadan</h3>
                    <p className="designation_text">
                      Consultant interanl medicine
                    </p>
                  </li> */}
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
      <HeroSlider data={sliderImages} />
      {/* <div className="st-hero-shape">
        <img src={bgShape} alt={bgShape} />
      </div> */}
    </div>
  );
};

export default Hero;
