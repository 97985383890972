import React, { useContext, useEffect, useState } from "react";
import Spacing from "../../Components/Spacing/Spacing";
import { axiosApi, dashboardUrl } from "../../axiosInstance";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { UserContext } from "../../Contexts/UseContext";
import Eye from "../../Components/PasswordEye/Eye";
import CloseEye from "../../Components/PasswordEye/CloseEye";
import Swal from "sweetalert2";
import { formatDate } from "../../utils/formatDate";
import "react-phone-number-input/style.css";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";

const AddFamily = () => {
  const { setPageTitle } = useContext(UserContext);

  useEffect(() => {
    setPageTitle("Add Family");
  }, []);

  const [input, setInput] = useState({
    firstname: "",
    lastname: "",
    phone: "",
    email: "",
    user_name: "",
    dateOfBirth: "",
    qualification: "",
    specialization: "",
    address: "",
    experience: "",
    description: "",
    password: "",
    confirmPassword: "",
    gender: "",
    fees: "",
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [specializations, setSpecializations] = useState([]);
  const [isFocused, setIsFocused] = useState(false);

  const validate = () => {
    const newErrors = {};
    const email = input.email;
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    let errorMessages = [];

    // Validate phone number
    if (input.phone && typeof input.phone === "string") {
      const valid = isValidPhoneNumber(input.phone);
      if (!valid) {
        newErrors.phone = "Please enter a valid phone number";
        errorMessages.push("Please enter a valid phone number");
      }
    }

    // Validate email
    if (!emailRegex.test(email)) {
      newErrors.email = "Enter a valid email address";
      errorMessages.push("Enter a valid email address");
    }

    // Validate password length
    if (input.password.length < 6) {
      newErrors.password = "Password should contain minimum 6 characters";
      errorMessages.push("Password should contain minimum 6 characters");
    }

    // Validate password confirmation
    if (input.password !== input.confirmPassword) {
      newErrors.confirmPassword = "Passwords do not match";
      errorMessages.push("Passwords do not match");
    }

    if (errorMessages.length > 0) {
      toast.warning(errorMessages[0]); // Show only the first error
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      !input.firstname ||
      !input.lastname ||
      !input.email ||
      !input.dateOfBirth ||
      !input.qualification ||
      !input.specialization ||
      !input.address ||
      !input.phone ||
      !input.password ||
      !input.confirmPassword ||
      !input.gender ||
      !input.fees
    ) {
      toast.warning("Please fill in all input fields");
      return;
    }

    const fullname = `${input?.firstname} ${input?.lastname}`;

    if (validate()) {
      setLoading(true);

      try {
        const data = {
          ...input,
          user_name: input.email,
          name: fullname,
        };
        delete data.firstname;
        delete data.lastname;

        const response = await axiosApi.post("/v1/doctor/Dr-sign-up", data);

        Swal.fire({
          title: "Signup successful!",
          text: "Click to continue to your Dashboard",
          icon: "success",
          confirmButtonText: "Go to Dashboard",
        }).then((result) => {
          window.location.href = dashboardUrl;
        });

        setLoading(false);
        setInput({
          firstname: "",
          lastname: "",
          phone: "",
          email: "",
          user_name: "",
          dateOfBirth: "",
          qualification: "",
          specialization: "",
          experience: "",
          description: "",
          address: "",
          password: "",
          confirmPassword: "",
          gender: "",
          fees: "",
        });

        setTimeout(() => {
          window.location.href = dashboardUrl;
        }, 6000);
      } catch (error) {
        console.error("Signup error:", error);
        if (error.response && error.response.status === 403) {
          toast.error("Email already exists. Please use a different email!");
        } else {
          toast.error("Signup failed. Please try again!");
        }
        setLoading(false);
      } finally {
        setLoading(false);
      }
    }
  };

  const fetchSpecializations = async () => {
    try {
      const response = await axiosApi.get("/v1/doctor/specializations");
      setSpecializations(response?.data?.specializations);
    } catch (error) {
      console.error("Error fetching specializations:", error);
    }
  };

  useEffect(() => {
    fetchSpecializations();
  }, []);

  const handleSpecializationChange = (e) => {
    setInput({ ...input, specialization: e.target.value });
  };

  const handlePhoneChange = (phone) => {
    setInput({ ...input, phone: phone });
    if (phone && typeof phone === "string") {
      const valid = isValidPhoneNumber(phone);
      if (valid) {
        setErrors((prevErrors) => ({ ...prevErrors, phone: "" }));
      }
    }
  };

  const handlePasswordChange = (e) => {
    const value = e.target.value;
    setInput({ ...input, password: value });
    if (value.length >= 6) {
      setErrors((prevErrors) => ({ ...prevErrors, password: "" }));
    }
    if (value === input?.confirmPassword) {
      setErrors((prevErrors) => ({ ...prevErrors, confirmPassword: "" }));
    }
  };

  const handleConfirmPasswordChange = (e) => {
    const value = e.target.value;
    setInput({ ...input, confirmPassword: value });
    if (value === input?.password) {
      setErrors((prevErrors) => ({ ...prevErrors, confirmPassword: "" }));
    }
  };

  const handleFeesChange = (e) => {
    setInput({ ...input, fees: e.target.value.replace(/\D/g, "") });
  };

  const handleExperienceChange = (e) => {
    setInput({ ...input, experience: e.target.value.replace(/\D/g, "") });
  };

  const currentDate = formatDate(new Date());

  return (
    <div className="container signup_main">
      <Spacing lg={80} md={80} />
      <div className="booking_container">
        <div className="booking_form_card">
          <form onSubmit={handleSubmit} className="signup_form">
            <h3
              style={{
                textAlign: "center",
                fontFamily: '"Inter", sans-serif',
                fontWeight: "500px",
                fontSize: "35px",
                color: "#00704A",
              }}
              className="booking_form_card_title"
            >
              Personal details
            </h3>
            <Spacing lg={30} md={20} />
            <div className="mb-3">
              <input
                type="text"
                className="form-control"
                id="firstname"
                placeholder="First Name"
                required
                value={input?.firstname}
                onChange={(e) =>
                  setInput({ ...input, firstname: e.target.value })
                }
              />
            </div>

            <div className="mb-3">
              <input
                type="text"
                className="form-control"
                id="lastname"
                placeholder="Last Name"
                required
                value={input?.lastname}
                onChange={(e) =>
                  setInput({ ...input, lastname: e.target.value })
                }
              />
            </div>

            <PhoneInput
              international
              defaultCountry="IN"
              countryCallingCodeEditable={false}
              value={input?.phone || ""}
              onChange={handlePhoneChange}
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
              className={isFocused ? "active_phone_input" : ""}
            />
            {errors?.phone && <p className="text-danger">{errors?.phone}</p>}

            <div className="mb-3 mt-3">
              <input
                type="text"
                className="form-control"
                id="age"
                placeholder="Age"
                required
                value={input?.email}
                onChange={(e) => setInput({ ...input, age: e.target.value })}
              />
            </div>

            <label htmlFor="gender" className="form-label mb-2 mt-1">
              Select Gender
            </label>
            <div className="input-group mb-3">
              <div className="form-check me-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="gender"
                  value="male"
                  id="genderMale"
                  checked={input.gender === "male"}
                  onChange={(e) =>
                    setInput({ ...input, gender: e.target.value })
                  }
                />
                <label className="form-check-label" htmlFor="genderMale">
                  Male
                </label>
              </div>
              <div className="form-check me-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="gender"
                  value="female"
                  id="genderFemale"
                  checked={input.gender === "female"}
                  onChange={(e) =>
                    setInput({ ...input, gender: e.target.value })
                  }
                />
                <label className="form-check-label" htmlFor="genderFemale">
                  Female
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="gender"
                  value="other"
                  id="genderOther"
                  checked={input.gender === "other"}
                  onChange={(e) =>
                    setInput({ ...input, gender: e.target.value })
                  }
                />
                <label className="form-check-label" htmlFor="genderOther">
                  Other
                </label>
              </div>
            </div>

            <div className="mb-3">
              <select
                type="text"
                className="form-control"
                id="relationship"
                required
                value={input?.relationship}
                onChange={handleFeesChange}
                style={{color: "#7d7d7d"}}
              >
                <option value="">Relationship</option>
                <option value="">Father</option>
                <option value="">Mother</option>
                <option value="">Son</option>
                <option value="">Daughter</option>
                <option value="">Wife</option>
                <option value="">Husband</option>
                <option value="">Friend</option>
                <option value="">Others</option>
              </select>
            </div>

            <div className="mb-3">
              <input
                type="email"
                className="form-control"
                id="email"
                placeholder="Email"
                required
                value={input?.email}
                onChange={(e) => setInput({ ...input, email: e.target.value })}
              />
            </div>

            <div className="mb-3">
              <input
                type="text"
                className="form-control"
                id="height"
                placeholder="Height"
                required
                value={input?.height}
                onChange={(e) => setInput({ ...input, height: e.target.value })}
              />
            </div>

            <div className="mb-3">
              <input
                type="text"
                className="form-control"
                id="weight"
                placeholder="Weight"
                required
                value={input?.weight}
                onChange={(e) => setInput({ ...input, weight: e.target.value })}
              />
            </div>

            <div className="mb-3">
              <input
                type="tel"
                className="form-control"
                id="emergency_number"
                placeholder="Emergency Number"
                required
                value={input?.emergency_number}
                onChange={handleFeesChange}
              />
            </div>

            <div className="mb-3">
              <select
                type="text"
                className="form-control"
                id="blood_group"
                required
                value={input?.blood_group}
                onChange={handleFeesChange}
                style={{color: "#7d7d7d"}}
              >
                <option value="">Blood Group</option>
                <option value="">A+</option>
                <option value="">B+</option>
                <option value="">AB+</option>
                <option value="">O+</option>
                <option value="">A-</option>
                <option value="">B-</option>
                <option value="">AB-</option>
                <option value="">O-</option>
              </select>
            </div>

            <div className="password-input-container mb-3">
              <select
                type="text"
                className="form-control"
                id="martial_status"
                value={input?.martial_status}
                onChange={handleExperienceChange}
                style={{color: "#7d7d7d"}}
              >
                <option value="">Martial Status</option>
                <option value="">Married</option>
                <option value="">Single</option>
                <option value="">Others</option>
              </select>
            </div>

            <div className="password-input-container mb-3">
              <textarea
                className="form-control"
                id="Allergic details"
                rows="3"
                placeholder="Allergic Details"
                required
                value={input?.allergic_details}
                onChange={(e) =>
                  setInput({ ...input, allergic_details: e.target.value })
                }
              ></textarea>
            </div>

            <Spacing lg={40} md={30} />
            <div className="booking_form_card_btn_wrapper">
              <button
                type="submit"
                className="signup_submit_btn"
                disabled={loading}
              >
                {loading ? <span className="loader"></span> : "Save"}
              </button>
            </div>
          </form>
        </div>
      </div>
      <Spacing lg={100} md={80} />
      <ToastContainer position="top-center" autoClose={2000} />
    </div>
  );
};

export default AddFamily;
