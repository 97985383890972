import React, { useContext, useEffect, useState } from "react";
import Spacing from "../../Components/Spacing/Spacing";
import { axiosApi, dashboardUrl } from "../../axiosInstance";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { UserContext } from "../../Contexts/UseContext";
import Eye from "../../Components/PasswordEye/Eye";
import CloseEye from "../../Components/PasswordEye/CloseEye";
import Swal from "sweetalert2";
import { formatDate } from "../../utils/formatDate";
import "react-phone-number-input/style.css";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { Navigate } from "react-router-dom";

const PatientProfile = () => {
  const { setPageTitle, patientId } = useContext(UserContext);

  useEffect(() => {
    setPageTitle("My Profile");
  }, []);

  const [input, setInput] = useState({
    name: "",
    phone: "",
    email: "",
    user_name: "",
    dateOfBirth: "",
    height: "",
    weight: "",
    emergency_contact: "",
    blood_group: "",
    maritial_status: "",
    allergic_details: "",
    gender: "",
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [patientDetails, setPatientDetails] = useState([]);

  const validate = () => {
    const newErrors = {};
    // const email = input.email;
    // const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    let errorMessages = [];

    // Validate phone number
    if (input.phone && typeof input.phone === "string") {
      const valid = isValidPhoneNumber(input.phone);
      if (!valid) {
        newErrors.phone = "Please enter a valid phone number";
        errorMessages.push("Please enter a valid phone number");
      }
    }

    if (input.emergency_contact && typeof input.emergency_contact === "string") {
      const valid = isValidPhoneNumber(input.emergency_contact);
      if (!valid) {
        newErrors.emergency_contact = "Please enter a valid emergency number";
        errorMessages.push("Please enter a valid emergency number");
      }
    }

    // if (!emailRegex.test(email)) {
    //   newErrors.email = "Enter a valid email address";
    //   errorMessages.push("Enter a valid email address");
    // }

    if (errorMessages.length > 0) {
      toast.warning(errorMessages[0]); // Show only the first error
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axiosApi.get(
        `/v1/patient/getpatient/${patientId}`
      );
      const data = response?.data?.Patient;

      setInput({
        name: data.name || "",
        phone: data.phone || "",
        dateOfBirth: reverseformatDate(data.dateOfBirth) || "",
        gender: data.gender || "",
        email: data.email || "",
        height: data.height || "",
        weight: data.weight || "",
        emergency_contact: data.emergency_contact || "",
        blood_group: data.blood_group || "",
        maritial_status: data.maritial_status || "",
        allergic_details: data.allergic_details || "",
      });
      setPatientDetails(response.data?.Patient);
    } catch (e) {
      setLoading(false);
      console.log("error occured", e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [patientId]);

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!input.name || !input.dateOfBirth || !input.phone || !input.gender) {
      toast.warning("Please fill the mandatory fields");
      return;
    }

    if (validate()) {
      setLoading(true);

      try {
        // const data = {
        //   ...input,
        //   user_name: input.email,
        // };

        const response = await axiosApi.put(
          `/v1/patient/updatepatient/${patientId}`,
          input
        );
        console.log(response?.data);
        toast.success("Details Updated successfully.!");

        // Swal.fire({
        //   title: "Details Updated successfully.!",
        //   // text: "Click to continue to your Dashboard",
        //   icon: "success",
        // });

        setPatientDetails(response?.data?.Patient);
        setLoading(false);
      } catch (error) {
        console.error("Signup error:", error);
        if (error.response && error.response.status === 403) {
          toast.error("Email already exists. Please use a different email!");
        } else {
          toast.error("Signup failed. Please try again!");
        }
        setLoading(false);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleBloodChange = (e) => {
    setInput({ ...input, blood_group: e.target.value });
  };

  const handleMartialChange = (e) => {
    setInput({ ...input, maritial_status: e.target.value });
  };

  const handlePhoneChange = (phone) => {
    setInput({ ...input, phone: phone });
    if (phone && typeof phone === "string") {
      const valid = isValidPhoneNumber(phone);
      if (valid) {
        setErrors((prevErrors) => ({ ...prevErrors, phone: "" }));
      }
    }
  };

  const handleEmergencyPhoneChange = (emergency_contact) => {
    setInput({ ...input, emergency_contact: emergency_contact });
    if (emergency_contact && typeof emergency_contact === "string") {
      const valid = isValidPhoneNumber(emergency_contact);
      if (valid) {
        setErrors((prevErrors) => ({ ...prevErrors, emergency_contact: "" }));
      }
    }
  };

  const handleAllergicDetails = (e) => {
    setInput({ ...input, allergic_details: e.target.value });
  };

  const reverseformatDate = (dateString) => {
    if (!dateString) return "";

    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  };

  const handleDateChange = (e) => {
    const date = new Date(e.target.value);
    const formattedDate = date.toISOString().split("T")[0];
    setInput({ ...input, dateOfBirth: formattedDate });
  };

  const currentDate = formatDate(new Date());

  return (
    <div className="container signup_main">
      <Spacing lg={120} md={80} />
      <div className="booking_container">
        <div className="booking_form_card">
          <form onSubmit={handleSubmit} className="signup_form">
            <h3
              style={{
                textAlign: "center",
                fontFamily: '"Inter", sans-serif',
                fontWeight: "500px",
                fontSize: "35px",
                color: "#00704A",
              }}
              className="booking_form_card_title"
            >
              Personal details
            </h3>
            <Spacing lg={30} md={20} />

            <div className="mb-3">
              <label htmlFor="gender" className="form-label mb-2 mt-1">
                Name<span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="text"
                className="form-control"
                id="firstname"
                required
                value={input?.name}
                onChange={(e) => setInput({ ...input, name: e.target.value })}
              />
            </div>

            <label htmlFor="gender" className="form-label mb-2 mt-1">
              Phone<span style={{ color: "red" }}>*</span>
            </label>
            <PhoneInput
              international
              defaultCountry="IN"
              countryCallingCodeEditable={false}
              value={input?.phone || ""}
              onChange={handlePhoneChange}
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
              className={isFocused ? "active_phone_input" : ""}
            />
            {errors?.phone && <p className="text-danger">{errors?.phone}</p>}

            <div className="mb-3 mt-3">
              <label htmlFor="gender" className="form-label mb-2 mt-1">
                Date Of Birth<span style={{ color: "red" }}>*</span>
              </label>
              <input
                id="dob"
                type="date"
                className="form-control"
                placeholder="DOB"
                required
                value={input?.dateOfBirth}
                max={currentDate}
                onChange={handleDateChange}
              />
            </div>

            <label htmlFor="gender" className="form-label mb-2 mt-1">
              Select Gender<span style={{ color: "red" }}>*</span>
            </label>
            <div className="input-group mb-3">
              <div className="form-check me-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="gender"
                  value="male"
                  id="genderMale"
                  checked={input.gender === "Male"}
                  onChange={(e) =>
                    setInput({ ...input, gender: e.target.value })
                  }
                />
                <label className="form-check-label" htmlFor="genderMale">
                  Male
                </label>
              </div>
              <div className="form-check me-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="gender"
                  value="female"
                  id="genderFemale"
                  checked={input.gender === "Female"}
                  onChange={(e) =>
                    setInput({ ...input, gender: e.target.value })
                  }
                />
                <label className="form-check-label" htmlFor="genderFemale">
                  Female
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="gender"
                  value="other"
                  id="genderOther"
                  checked={input.gender === "Other"}
                  onChange={(e) =>
                    setInput({ ...input, gender: e.target.value })
                  }
                />
                <label className="form-check-label" htmlFor="genderOther">
                  Other
                </label>
              </div>
            </div>

            <div className="mb-3">
              <label htmlFor="gender" className="form-label mb-2 mt-1">
                Email
              </label>
              <input
                type="email"
                className="form-control"
                id="email"
                value={input?.email}
                onChange={(e) => setInput({ ...input, email: e.target.value })}
                readOnly
              />
            </div>

            <div className="mb-3">
              <label htmlFor="gender" className="form-label mb-2 mt-1">
                Height
              </label>
              <input
                type="number"
                className="form-control"
                id="height"
                value={input?.height}
                onChange={(e) => setInput({ ...input, height: e.target.value })}
              />
            </div>

            <div className="mb-3">
              <label htmlFor="gender" className="form-label mb-2 mt-1">
                Weight
              </label>
              <input
                type="number"
                className="form-control"
                id="weight"
                value={input?.weight}
                onChange={(e) => setInput({ ...input, weight: e.target.value })}
              />
            </div>

            <div className="mb-3">
              <label htmlFor="gender" className="form-label mb-2 mt-1">
                Emergency Number
              </label>
              <PhoneInput
                international
                defaultCountry="IN"
                countryCallingCodeEditable={false}
                value={input?.emergency_contact || ""}
                onChange={handleEmergencyPhoneChange}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
                className={isFocused ? "active_phone_input" : ""}
              />
              {errors?.emergency_contact && (
                <p className="text-danger">{errors?.emergency_contact}</p>
              )}
            </div>

            <div className="mb-3">
              <label htmlFor="gender" className="form-label mb-2 mt-1">
                Blood Group
              </label>
              <select
                type="text"
                className="form-control"
                id="blood_group"
                value={input?.blood_group}
                onChange={handleBloodChange}
                style={{ color: "#7d7d7d" }}
              >
                <option value=""></option>
                <option value="A+">A+</option>
                <option value="B+">B+</option>
                <option value="AB+">AB+</option>
                <option value="O+">O+</option>
                <option value="A-">A-</option>
                <option value="B-">B-</option>
                <option value="AB-">AB-</option>
                <option value="O-">O-</option>
              </select>
            </div>

            <div className="password-input-container mb-3">
              <label htmlFor="gender" className="form-label mb-2 mt-1">
                Martial Status
              </label>
              <select
                type="text"
                className="form-control"
                id="maritial_status"
                value={input?.maritial_status}
                onChange={handleMartialChange}
                style={{ color: "#7d7d7d" }}
              >
                <option value=""></option>
                <option value="Married">Married</option>
                <option value="Single">Single</option>
                <option value="Others">Others</option>
              </select>
            </div>

            <div className="password-input-container mb-3">
              <label htmlFor="gender" className="form-label mb-2 mt-1">
                Allergic Details
              </label>
              <textarea
                className="form-control"
                id="allergic_details"
                rows="3"
                value={input?.allergic_details}
                onChange={handleAllergicDetails}
              ></textarea>
            </div>

            <Spacing lg={40} md={30} />
            <div className="booking_form_card_btn_wrapper">
              <button
                type="submit"
                className="signup_submit_btn"
                disabled={loading}
              >
                {loading ? <span className="loader"></span> : "Update"}
              </button>
            </div>
          </form>
        </div>
      </div>
      <Spacing lg={100} md={80} />
      <ToastContainer position="top-center" autoClose={2000} />
    </div>
  );
};

export default PatientProfile;
