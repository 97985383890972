import React, { useEffect, useRef, useState } from "react";
import SectionHeading from "../SectionHeading/SectionHeading";
import Specialists from "../Specialists/Specialists";
import ReactPaginate from "react-paginate";
import Spacing from "../../Components/Spacing/Spacing";
import { axiosApi } from "../../axiosInstance";
import { Icon } from "@iconify/react";

const SpecialistsSlider = ({ data }) => {
  const [loading, setLoading] = useState(false);
  const [totalDoctors, setTotalDoctors] = useState(0);
  const [alldoctors, setAllDoctors] = useState([]);
  const [paginationDetails, setPaginationDetails] = useState({});
  const [filteredDoctors, setFilteredDoctors] = useState([]);
  const [page, setPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [locationQuery, setLocationQuery] = useState("");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const pageSize = 20;
  const [useMylocation, setUseMyLocation] = useState(null);
  const [error, setError] = useState(null);
  const [locationText, setLocationText] = useState("Search Location");
  const [suggestions, setSuggestions] = useState([]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axiosApi.get(
        `/v1/doctor/getallweb?pageSize=${pageSize}&page=${page}&keyword=${searchQuery}&location=${locationQuery}`
      );
      setPaginationDetails(response?.data?.pageInfo);
      setTotalDoctors(response?.data?.Doctors?.count);
      setAllDoctors(response?.data?.Doctors);
      setFilteredDoctors(response?.data?.Doctors);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  // Updated Search Logic with Debounce
  const handleSearchChange = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);

    setLoading(true);
    setTimeout(() => {
      const filtered = alldoctors.filter((doctor) => {
        const matchesDoctorInfo =
          doctor.name?.toLowerCase().includes(query) ||
          doctor.specialization?.toLowerCase().includes(query);

        const matchesClinicInfo = doctor.Drclinic?.some((clinic) => {
          return (
            clinic.Clinic?.name?.toLowerCase().includes(query) ||
            clinic.Clinic?.place?.toLowerCase().includes(query)
          );
        });

        return matchesDoctorInfo || matchesClinicInfo;
      });

      setFilteredDoctors(filtered);
      setLoading(false);
    }, 500);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Check if the click target is outside the dropdown area
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false); // Close the dropdown
      }
    };

    // Add event listener
    document.addEventListener("click", handleClickOutside);

    // Cleanup event listener
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handlePageClick = (event) => {
    setPage(event.selected + 1);
  };

  // Toggle dropdown visibility
  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  useEffect(() => {
    if (locationQuery) {
      setLocationText(locationQuery);  // Show the current query if available
    } else {
      setLocationText("Search Location");  // Default text when no query
    }
  }, [locationQuery]);

  const getSuggestions = async (query) => {
    try {
      const response = await axiosApi.get(
        `/v1/suggestion/suggestLocations?query=${query}`
      );
      if(query.length > 0) {
        setSuggestions(response.data?.suggestions || []);
      } else {
        setSuggestions([]);
      }
    } catch (e) {
      console.error("Error fetching suggestions:", error);
      setSuggestions([]);
    }
  };

  const handleLocationChange = (e) => {
    const query = e.target.value;
    setLocationQuery(query);
    if (query.length > 0) {
      getSuggestions(query);
    } else {
      setSuggestions([]);
    }
  };

  const handleSuggestionClick = (suggestion) => {
    setLocationQuery(suggestion);
    setSuggestions([]);
    setDropdownOpen(false);
  };

  useEffect(() => {
    fetchData();
  }, [page, searchQuery, locationQuery]);

  const getMyLocation = async () => {
    setLoading(true);
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;
          const location = { latitude, longitude };

          setUseMyLocation(location);

          try {
            const googlelocation = `{"lat":${latitude},"long":${longitude}}`;
            const response = await axiosApi.get(
              `/v1/doctor/getalldoctorcurrentloc?pageSize=${pageSize}&page=${page}&googlelocation=${googlelocation}`
            );
            setFilteredDoctors(response?.data?.Doctors);
            setLocationText("My Current location");
          } catch (e) {
            console.log("Error", e);
          } finally {
            setLoading(false);
          }

          setError(null);
        },
        (err) => {
          switch (err.code) {
            case err.PERMISSION_DENIED:
              setError("User denied the request for Geolocation.");
              break;
            case err.POSITION_UNAVAILABLE:
              setError("Location information is unavailable.");
              break;
            case err.TIMEOUT:
              setError("The request to get user location timed out.");
              break;
            default:
              setError("An unknown error occurred.");
              break;
          }
        }
      );
    } else {
      setError("Geolocation is not supported by this browser.");
    }
  };

  return (
    <section id="doctors">
      <div className="st-height-b120 st-height-lg-b80" />
      <SectionHeading title="Meet Our Specialists" />
      <div className="container">
        {/* <div className="search_container">
          <div
            className="location-search-container h-9 divide-x"
            style={{ display: "flex" }}
          >
            <div
              className="location_section"
              onClick={toggleDropdown}
              ref={dropdownRef}
              role="button"
            >
              <div
                style={{ display: "flex", alignItems: "center", gap: "5px" }}
              >
                <Icon icon="fa6-solid:location-dot" className="location_icon" />
                <div className="location_text"> {locationText} </div>
                <Icon icon="fa6-solid:angle-down" className="down_arrow_icon" />
              </div>
              {dropdownOpen && (
                <div id="locationDropdown" className={`dropdown_container ${suggestions.length > 0 ? 'has-suggestions' : ''}`} >
                  <div className="locationDropdown-image-intput">
                    <img
                      src={`${process.env.PUBLIC_URL}/icons/search_icon.svg`}
                      alt="search"
                      className="search_icon"
                      style={{ marginTop: "7px" }}
                    />
                    <input
                      type="text"
                      className="dropdown_input"
                      placeholder="Search Location"
                      value={locationQuery}
                      onClick={(e) => e.stopPropagation()}
                      onChange={handleLocationChange}
                    />
                  </div>
                  <div
                    className="dropdown_option"
                    style={{ display: "flex", gap: "5px" }}
                    onClick={getMyLocation}
                  >
                    <Icon
                      icon="fa6-solid:location-dot"
                      className="location_icon"
                    />
                    <div>Use my location</div>
                  </div>
                  {suggestions.length > 0 && (
                    <div className="suggestions_list">
                      {suggestions.map((suggestion, index) => (
                        <div
                          key={index}
                          className="dropdown_option"
                          onClick={() => handleSuggestionClick(suggestion)}
                        >
                          {suggestion}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              )}
            </div>

            <div className="search_section">
              <img
                src={`${process.env.PUBLIC_URL}/icons/search_icon.svg`}
                alt="searchIcon"
                className="search_icon"
              />
              <input
                type="text"
                className="search_input"
                placeholder="Search for Doctors, Hospitals & Specializations"
                value={searchQuery}
                onChange={handleSearchChange}
              />
            </div>
          </div>
        </div> */}

        {loading ? (
          <div className="custom-loader_container">
            <span className="custom-loader"></span>
          </div>
        ) : (
          <>
            {filteredDoctors?.length > 0 ? (
              <div className="st-slider-style3">
                {filteredDoctors?.map((doctor) => (
                  <Specialists {...doctor} key={doctor?.doctor_id} />
                ))}
              </div>
            ) : (
              <p
                style={{
                  fontSize: "14px",
                  textAlign: "center",
                  marginTop: "6rem",
                }}
              >
                No Doctors Found
              </p>
            )}
          </>
        )}

        {paginationDetails?.totalPages > 1 && (
          <>
            <Spacing lg={60} md={30} />
            <div>
              <ReactPaginate
                previousLabel={
                  <img
                    src={`${process.env.PUBLIC_URL}/icons/pagination-arrow.svg`}
                    alt="Previous"
                  />
                }
                nextLabel={
                  <img
                    style={{ rotate: "180deg" }}
                    src={`${process.env.PUBLIC_URL}/icons/pagination-arrow.svg`}
                    alt="Next"
                  />
                }
                breakLabel={"..."}
                pageCount={paginationDetails?.totalpages || 0}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName={"custom-pagination"}
                activeClassName={"active"}
                previousClassName={"previous"}
                nextClassName={"next"}
              />
            </div>
          </>
        )} 
      </div>
      {/* <div className="st-height-b120 st-height-lg-b80" /> */}
    </section>
  );
};

export default SpecialistsSlider;
