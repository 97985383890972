import React, { useContext, useEffect, useState } from "react";
import { axiosApi } from "../../axiosInstance";
import { UserContext } from "../../Contexts/UseContext";
import { reverseFormatDate } from "../../utils/formatDate";
import { formatTime } from "../../utils/FormatTime";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";

const BookStatus = () => {
  const { setPageTitle, userDetails } = useContext(UserContext);

  useEffect(() => {
    setPageTitle("Booking Status");
  }, []);

  const userId = userDetails?.user_id;

  const [loading, setLoading] = useState(false);
  const [bookings, setBookings] = useState([]);
  const [selectedTab, setSelectedTab] = useState("Upcoming");
  const [cancelledBookings, setCancelledBookings] = useState([]);
  const [completedBookings, setCompletedBookings] = useState([]);
  const [buttonLoading, setButtonLoading] = useState(false);
  // const [showDiv, setShowDiv] = useState(false);

  function encodeDoctorId(doctorId) {
    return btoa(doctorId.toString()); // Ensure doctorId is a string before encoding
  }

  useEffect(() => {
    const fetchBookingDetails = async () => {
      setLoading(true);
      try {
        const response = await axiosApi.get(
          `/v1/booking/getallbooking/${userId}`
        );
        const consultations = response?.data?.consultations;
        setBookings(consultations || []);
      } catch (error) {
        console.error("Error fetching booking details:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchBookingDetails();
  }, [userId]);

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setShowDiv(true);
  //   }, 10000);

  //   return () => clearTimeout(timer);
  // }, []);

  const handleTabClick = (tab) => {
    setSelectedTab(tab);
  };

  const getCancelledAppointments = async () => {
    setLoading(true);
    try {
      const response = await axiosApi.get(
        `/v1/consultation/cancelledBooking/${userId}`
      );
      setCancelledBookings(response?.data?.cancelledBookings || []);
    } catch (e) {
      setLoading(false);
      console.log("Error Occured,", e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCancelledAppointments();
  }, [userId]);

  const getCompletedAppointments = async () => {
    setLoading(true);
    try {
      const response = await axiosApi.get(
        `/v1/consultation/completedBooking/${userId}`
      );
      setCompletedBookings(response?.data?.completedBookings || []);
    } catch (e) {
      setLoading(false);
      console.log("Error Occured,", e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCompletedAppointments();
  }, [userId]);

  const handleCancelAppointments = async (bookingId) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "Do you want to cancel this booking?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, cancel it!",
    });

    if (result.isConfirmed) {
      setButtonLoading(true);
      try {
        const response = await axiosApi.post(
          `/v1/consultation/cancel/${bookingId}`
        );
        // console.log(response?.data?.bookingdetails);
        const cancelBooking = response?.data?.bookingdetails;
        
        setBookings((prev) =>
          prev.filter((booking) => booking?.booking?.booking_id !== bookingId)
        );
        setCancelledBookings((prevCancel) => [cancelBooking, ...prevCancel]);
        Swal.fire({
          title: "Cancelled!",
          text: "Your booking has been successfully cancelled.",
          icon: "success",
          confirmButtonText: "OK",
        });
      } catch (e) {
        setButtonLoading(false);
        Swal.fire({
          title: "Error",
          text: "Something went wrong while cancelling your booking.",
          icon: "error",
          confirmButtonText: "OK",
        });
        console.log("Error Occured", e);
      } finally {
        setButtonLoading(false);
      }
    }
  };

  return (
    <div>
      <div className="st-height-b120 st-height-lg-b80" />
      <div className="container">
        <div className="details_wrapper">
          <div className="profile_details_container book_status-box">
            <div className="doctor_name" style={{ marginTop: "10px" }}>
              Appointments
            </div>
            {/* Tabs */}
            <div
              role="tablist"
              aria-orientation="horizontal"
              className="appointments-tab-container"
            >
              <button
                className={selectedTab === "Upcoming" ? "active-tab" : ""}
                role="tab"
                type="button"
                onClick={() => handleTabClick("Upcoming")}
                style={{
                  padding: "10px 20px",
                  border: "none",
                  background: "transparent",
                  cursor: "pointer",
                  fontWeight: "600",
                  borderBottom:
                    selectedTab === "Upcoming" ? "2px solid #006421" : "none",
                }}
              >
                Upcoming
              </button>
              <button
                className={selectedTab === "Completed" ? "active-tab" : ""}
                role="tab"
                type="button"
                onClick={() => handleTabClick("Completed")}
                style={{
                  padding: "10px 20px",
                  border: "none",
                  background: "transparent",
                  cursor: "pointer",
                  fontWeight: "600",
                  borderBottom:
                    selectedTab === "Completed" ? "2px solid #006421" : "none",
                }}
              >
                Completed
              </button>
              <button
                className={selectedTab === "Cancelled" ? "active-tab" : ""}
                role="tab"
                type="button"
                onClick={() => handleTabClick("Cancelled")}
                style={{
                  padding: "10px 20px",
                  border: "none",
                  background: "transparent",
                  cursor: "pointer",
                  fontWeight: "600",
                  borderBottom:
                    selectedTab === "Cancelled" ? "2px solid #006421" : "none",
                }}
              >
                Cancelled
              </button>
            </div>
            {selectedTab === "Upcoming" && (
              <>
                {loading ? (
                  <div className="custom-loader_container">
                    <span className="custom-loader"></span>
                  </div>
                ) : (
                  <>
                    {bookings && bookings?.length > 0 ? (
                      <div
                        style={{ overflow: "auto", textAlign: "center" }}
                        className="table_parent"
                      >
                        <table className="table table_width">
                          <thead>
                            <tr className="table_head">
                              <th scope="col">Sl No</th>
                              <th scope="col">Name</th>
                              <th scope="col">Token Number</th>
                              <th scope="col">Date</th>
                              <th scope="col">Time</th>
                              <th scope="col">Doctor's Name</th>
                              <th scope="col">Clinic Name</th>
                              <th scope="col">Queue Position</th>
                              <th scope="col">Cancel Booking</th>
                            </tr>
                          </thead>
                          <tbody>
                            {bookings?.map((booking, index) => (
                              <tr
                                key={booking?.booking?.booking_id}
                                className="table-row"
                              >
                                <td>{index + 1}</td>
                                <td style={{ textTransform: "capitalize" }}>
                                  {booking?.booking?.Patient?.name}
                                </td>
                                <td>{booking?.booking?.token}</td>
                                <td>
                                  {reverseFormatDate(
                                    booking?.booking?.schedule_date
                                  )}
                                </td>
                                {booking?.booking?.schedule_time && (
                                  <td>
                                    {formatTime(
                                      booking?.booking?.schedule_time
                                    )}
                                  </td>
                                )}
                                <td>{booking?.booking?.Doctor?.name}</td>
                                <td>{booking?.booking?.Clinic?.name}</td>
                                <td className="queue_position">
                                  {booking?.targetBookingPosition}
                                </td>
                                <td>
                                  <button
                                    style={{
                                      backgroundColor: "rgb(231 81 90)",
                                      color: "white",
                                      border: "1px solid rgb(231 81 90)"
                                    }}
                                    onClick={() => {
                                      handleCancelAppointments(
                                        booking?.booking?.booking_id
                                      );
                                    }}
                                  >
                                    {buttonLoading[
                                      booking?.booking?.booking_id
                                    ] ? (
                                      <span className="loader"></span>
                                    ) : (
                                      "Cancel Now"
                                    )}
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <>
                        <div className="empty_data">
                          <img
                            src="https://static.thenounproject.com/png/486159-200.png"
                            alt="No Data"
                          />
                          <p>Data not found</p>
                        </div>
                      </>
                    )}
                  </>
                )}
              </>
            )}
            {selectedTab === "Completed" && (
              <>
                {loading ? (
                  <div className="custom-loader_container">
                    <span className="custom-loader"></span>
                  </div>
                ) : (
                  <>
                    {completedBookings && completedBookings?.length > 0 ? (
                      <div
                        style={{ overflow: "auto", textAlign: "center" }}
                        className="table_parent"
                      >
                        <table className="table table_width">
                          <thead>
                            <tr className="table_head">
                              <th scope="col">Sl No</th>
                              <th scope="col">Name</th>
                              <th scope="col">Token Number</th>
                              <th scope="col">Date</th>
                              <th scope="col">Time</th>
                              <th scope="col">Doctor's Name</th>
                              <th scope="col">Clinic Name</th>
                            </tr>
                          </thead>
                          <tbody>
                            {completedBookings?.map((booking, index) => (
                              <tr
                                key={booking?.booking?.booking_id}
                                className="table-row"
                              >
                                <td>{index + 1}</td>
                                <td style={{ textTransform: "capitalize" }}>
                                  {booking?.Patient?.name}
                                </td>
                                <td>{booking?.token}</td>
                                <td>
                                  {reverseFormatDate(booking?.schedule_date)}
                                </td>
                                {booking?.schedule_time && (
                                  <td>{formatTime(booking?.schedule_time)}</td>
                                )}
                                <td>{booking?.Doctor?.name}</td>
                                <td>{booking?.Clinic?.name}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <>
                        <div className="empty_data">
                          <img src="https://static.thenounproject.com/png/486159-200.png" />
                          Data not found
                        </div>
                      </>
                    )}
                  </>
                )}
              </>
            )}
            {selectedTab === "Cancelled" && (
              <>
                {loading ? (
                  <div className="custom-loader_container">
                    <span className="custom-loader"></span>
                  </div>
                ) : (
                  <>
                    {cancelledBookings && cancelledBookings?.length > 0 ? (
                      <div
                        style={{ overflow: "auto", textAlign: "center" }}
                        className="table_parent"
                      >
                        <table className="table table_width">
                          <thead>
                            <tr className="table_head">
                              <th scope="col">Sl No</th>
                              <th scope="col">Name</th>
                              <th scope="col">Token Number</th>
                              <th scope="col">Date</th>
                              <th scope="col">Time</th>
                              <th scope="col">Doctor's Name</th>
                              <th scope="col">Clinic Name</th>
                              <th scope="col">ReBook</th>
                            </tr>
                          </thead>
                          <tbody>
                            {cancelledBookings?.map((booking, index) => (
                              <tr
                                key={booking?.booking?.booking_id}
                                className="table-row"
                              >
                                <td>{index + 1}</td>
                                <td style={{ textTransform: "capitalize" }}>
                                  {booking?.Patient?.name}
                                </td>
                                <td>{booking?.token}</td>
                                <td>
                                  {reverseFormatDate(booking?.schedule_date)}
                                </td>
                                {booking?.schedule_time && (
                                  <td>{formatTime(booking?.schedule_time)}</td>
                                )}
                                <td>{booking?.Doctor?.name}</td>
                                <td>{booking?.Clinic?.name}</td>
                                <td>
                                  <Link
                                    key={booking?.Doctor?.doctor_id}
                                    to={`/doctor-profile/${encodeDoctorId(
                                      booking?.Doctor?.doctor_id
                                    )}`}
                                  >
                                    <button style={{backgroundColor: "#006421", color: "white", border: "1px solid #006421"}}>Book Again</button>
                                  </Link>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <>
                        <div className="empty_data">
                          <img src="https://static.thenounproject.com/png/486159-200.png" />
                          Data not found
                        </div>
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <div className="st-height-b120 st-height-lg-b90" />
    </div>
  );
};

export default BookStatus;
