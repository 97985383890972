import React, { useContext, useEffect } from "react";
import Hero from "../Components/Hero/Hero";
import LocationInMap from "../Components/Map/LocationInMap";
import Contact from "../Components/Contact/Contact";
import Department from "../Components/Department/Department";
import Appointment from "../Components/Appointment/Appointment";
import About from "../Components/About/About";
import Iconbox from "../Components/Iconbox/Iconbox";
import PostWrapper from "../Components/Post/PostWrapper";
import SpecialistsSlider from "../Components/Slider/SpecialistsSlider";
import TestimonialSlider from "../Components/Slider/TestimonialSlider";
import BrandSlider from "../Components/Slider/BrandSlider";
import Newsletter from "../Components/Newsletter/Newsletter";
import Accordion from "../Components/Accordion/Accordion";
import PriceSlider from "../Components/Slider/PriceSlider";
import Funfact from "../Components/Funfact/Funfact";
import BeforeAfter from "../Components/BeforeAfter/BeforeAfter";
import MasonryGallery from "../Components/Gallery/Gallery";
import { UserContext } from "../Contexts/UseContext";
import AvailableDoctors from "../Components/Slider/AvailableDoctor";
import AvailableHospitals from "../Components/Slider/AvailableHospitals";

const heroData = {
  bgImg: "images/hero-bg.jpg",
  bgShape: "shape/hero-shape.png",
  sliderImages: [
    {
      img: "images/hero-img.png",
    },
    {
      img: "images/hero-img1.png",
    },
    {
      img: "images/hero-img2.png",
    },
    {
      img: "images/hero-img.png",
    },
    {
      img: "images/hero-img1.png",
    },
    {
      img: "images/hero-img2.png",
    },
  ],
  title: ["Crutches", "Laboratory", "Cardiology", "Dentist", "Neurology"],
};

const testimonialData = [
  {
    img: "images/avatar2.png",
    name: "Ralph Jones",
    subTitle:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum the industry's standard dummy text.",
  },
  {
    img: "images/avatar3.png",
    name: "Francis Jara",
    subTitle:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum the industry's standard dummy text.",
  },
  {
    img: "images/avatar4.png",
    name: "David Baer",
    subTitle:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum the industry's standard dummy text.",
  },
  {
    img: "images/avatar2.png",
    name: "Davy Jones",
    subTitle:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum the industry's standard dummy text.",
  },
  {
    img: "images/avatar3.png",
    name: "Sebas Jara",
    subTitle:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum the industry's standard dummy text.",
  },
  {
    img: "images/avatar4.png",
    name: "Jacob Baer",
    subTitle:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum the industry's standard dummy text.",
  },
];

const faqData = {
  title: "Frequently Asked Questions",
  img: "images/faq-img.png",
  bgImg: "shape/faq-bg.svg",
  faqItems: [
    {
      title: "What is Medi solution?",
      content: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since when an unknown printer took a galley of type and scrambled it to make a type specimen book.`,
    },
    {
      title: "How do I get a refill on my prescription?",
      content: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since when an unknown printer took a galley of type and scrambled it to make a type specimen book.`,
    },
    {
      title: "How competent our total treatment?",
      content: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since when an unknown printer took a galley of type and scrambled it to make a type specimen book.`,
    },
    {
      title: "If I get sick what should I do?",
      content: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since when an unknown printer took a galley of type and scrambled it to make a type specimen book.`,
    },
    {
      title: "What is emergency cary to your hospital?",
      content: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since when an unknown printer took a galley of type and scrambled it to make a type specimen book.`,
    },
  ],
};

const newsletterData = {
  bgImg: "images/news-letter-bg.png",
  contact: "(+01) - 234 567 890",
};

const postData = [
  {
    img: "images/blog1.jpg",
    title: "Working in emergency medicine",
    date: "Aug 23, 2020",
    author: "Albert Brian",
    authorLink: "",
    subTitle:
      "Lorem Ipsum is simply dummy text of the print ing and typesetting industry. lorem Ipsum the industry's standard dummy text.",
    postLink: "/post/post_details",
  },
  {
    img: "images/blog2.jpg",
    title: "Individual treatment & assistance",
    date: "Aug 22, 2020",
    author: "William Juarez",
    authorLink: "",
    subTitle:
      "Lorem Ipsum is simply dummy text of the print ing and typesetting industry. lorem Ipsum the industry's standard dummy text.",
    postLink: "/post/post_details",
  },
  {
    img: "images/blog3.jpg",
    title: "Child’s first hospital visit",
    date: "Aug 21, 2020",
    author: "Jamse Lewis",
    authorLink: "",
    subTitle:
      "Lorem Ipsum is simply dummy text of the print ing and typesetting industry. lorem Ipsum the industry's standard dummy text.",
    postLink: "/post/post_details",
  },
  {
    img: "images/blog3.jpg",
    title: "Child’s first hospital visit",
    date: "Aug 21, 2020",
    author: "Jamse Lewis",
    authorLink: "",
    subTitle:
      "Lorem Ipsum is simply dummy text of the print ing and typesetting industry. lorem Ipsum the industry's standard dummy text.",
    postLink: "/post/post_details",
  },
];

const mapLocationURL =
  "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d387191.0361360884!2d-74.30933863574961!3d40.697539951885474!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c24fa5d33f083b%3A0xc80b8f06e177fe62!2sNew%20York%2C%20NY%2C%20USA!5e0!3m2!1sen!2sin!4v1720680729045!5m2!1sen!2sin";

const Home = () => {
  const { setBookingDetails } = useContext(UserContext);

  useEffect(() => {
    setBookingDetails({
      doctor_id: null,
      clinic_id: null,
      patient_id: null,
      schedule_date: "",
      schedule_time: "",
      type: "application",
      DoctorTimeSlot_id: null,
    });
    sessionStorage.removeItem("isDoctorBooking");
  }, []);
  return (
    <>
      <Hero data={heroData} />
      {/* <Iconbox data={iconboxData} /> */}
      {/* <About data={aboutData} /> */}
      <Department />
      <SpecialistsSlider />
      <AvailableDoctors />
      <AvailableHospitals />
      {/* <Appointment /> */}
      {/* <hr /> */}
      {/* <MasonryGallery /> */}
      {/* <BeforeAfter data={beforeAfterData} /> */}
      <TestimonialSlider data={testimonialData} />
      <Funfact />
      {/* <PriceSlider data={priceData} /> */}
      <Accordion data={faqData} />
      {/* <PostWrapper data={postData} /> */}
      {/* <BrandSlider data={brandData} /> */}
      <Contact />
      <Newsletter data={newsletterData} />
      {/* <About data={aboutData} /> */}
      {/* <LocationInMap data={mapLocationURL} /> */}
    </>
  );
};

export default Home;
