import React, { useState, useEffect, useContext, useRef } from "react";
import { Link } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
import { UserContext } from "../../Contexts/UseContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Icon } from "@iconify/react";
import { axiosApi } from "../../axiosInstance";

const Header = ({ data }) => {
  const { setUserDetails, bookingCompleted } = useContext(UserContext);
  const { logo } = data;

  const sidebarRef = useRef(null);

  const [mobileToggle, setMobileToggle] = useState(false);
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [isSticky, setIsSticky] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleMobileToggle = () => {
    setMobileToggle(!mobileToggle);
  };

  const handleLogout = () => {
    setUserDetails(null);
    sessionStorage.clear();
    toast.success("Logout Successfully");
  };

  useEffect(() => {
    const handleScroll = () => {
      const windowTop = window.scrollY || document.documentElement.scrollTop;

      if (windowTop >= headerHeight) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }

      setLastScrollTop(windowTop);
    };

    const headerHeight =
      document.querySelector(".st-sticky-header").offsetHeight + 100;

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isSticky, lastScrollTop]);

  useEffect(() => {
    const toggleBodyOverflow = () => {
      const body = document.querySelector("body");
      const scrollbarWidth =
        window.innerWidth - document.documentElement.clientWidth;

      if (mobileToggle) {
        body.style.overflow = "hidden";
        body.style.position = "fixed";
        body.style.width = "100%";
        body.style.top = "0";
        body.style.paddingRight = `${scrollbarWidth}px`;
      } else {
        body.style.overflow = "auto";
        body.style.position = "relative";
        body.style.paddingRight = "0";
      }
    };

    toggleBodyOverflow();

    return () => {
      const body = document.querySelector("body");
      body.style.overflow = "auto";
      body.style.position = "relative";
      body.style.paddingRight = "0";
    };
  }, [mobileToggle]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setMobileToggle(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <header
      className={`st-site-header st-style1 st-sticky-header ${
        isSticky ? "st-sticky-active" : ""
      }`}
    >
      <div className="st-main-header">
        <div className="container">
          <div className="st-main-header-in">
            <div
              className={`st-munu-toggle ${
                mobileToggle ? "st-toggle-active" : ""
              }`}
              onClick={handleMobileToggle}
            >
              <span></span>
            </div>

            <div className="st-main-header-left">
              <ScrollLink to="home" className="st-site-branding">
                <img src={logo} alt="Logo" className="st-site-branding-img" />
              </ScrollLink>
            </div>

            <div className="st-main-header-right">
              <div className="st-nav">
                <ul className="st-nav-list st-onepage-nav">
                  <li>
                    <Link
                      to="/"
                      spy={true}
                      duration={500}
                      onClick={() => {
                        setMobileToggle(false);
                      }}
                    >
                      Home
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/contact-us"
                      spy={true}
                      duration={500}
                      onClick={() => setMobileToggle(false)}
                    >
                      Contact Us
                    </Link>
                  </li>
                  <span
                    style={{
                      color: "#006421",
                      fontWeight: "600",
                      fontSize: "14px",
                      marginTop: "14px",
                    }}
                  >
                    <Link to="/patient-login">
                      <Icon
                        icon="fa6-solid:user"
                        className="user-icon"
                        style={{
                          marginRight: "6px",
                          marginTop: "-2px",
                          border: "1px solid",
                          borderRadius: "50%",
                          width: "15px",
                          height: "15px",
                          padding: "1px",
                        }}
                      />
                      Sign In
                    </Link>
                  </span>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`st-header-sidebar ${
          mobileToggle ? "show-st-header-sidebar" : ""
        }`}
        ref={sidebarRef}
      >
        <div className="st-header-sidebar-st-head">
          <img src={logo} alt="Logo" className="st-site-branding-img-sidebar" />
          <div
            className="st-header-sidebar-close-btn"
            onClick={handleMobileToggle}
          >
            &times;
          </div>
        </div>
        <div className="st-header-sidebar-pad">
          <Link to="/">
            <div className="st-sidebar-menu-item">
              <Icon
                icon="material-symbols:home"
                className="family-icon"
                style={{ marginRight: "16px", marginTop: "2px" }}
              />
              <p>Home</p>
            </div>
          </Link>
          <Link to="/contact-us">
            <div className="st-sidebar-menu-item">
              <Icon
                icon="fa-solid:phone-square"
                className="user-icon"
                style={{ marginRight: "16px" }}
              />
              Contact Us
            </div>
          </Link>
          <Link to="/patient-login">
            <div className="st-sidebar-menu-item">
              <Icon
                icon="fa6-solid:user"
                className="user-icon"
                style={{ marginRight: "16px" }}
              />
              Sign In
            </div>
          </Link>
        </div>
      </div>

      <ToastContainer autoClose={2000} />
    </header>
  );
};

export default Header;
