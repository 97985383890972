import React, { useContext, useEffect, useState } from "react";
import SectionHeading from "../../Components/SectionHeading/SectionHeading";
import Specialists from "../../Components/Specialists/Specialists";
import ReactPaginate from "react-paginate";
import Spacing from "../../Components/Spacing/Spacing";
import { axiosApi, imageBase_URL } from "../../axiosInstance";
import { Icon } from "@iconify/react";
import { UserContext } from "../../Contexts/UseContext";
import { Link } from "react-router-dom";

const HospitalsAvailable = ({ data }) => {
  const { setPageTitle } = useContext(UserContext);

  useEffect(() => {
    setPageTitle("Clinics");
  }, []);

  const [loading, setLoading] = useState(false);
  const [nearclinic, setNearClinic] = useState([]);
  const [page, setPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [locationQuery, setLocationQuery] = useState("");
  const pageSize = 10;

  function encodeDoctorId(clinicId) {
    return btoa(clinicId.toString()); // Ensure doctorId is a string before encoding
  }

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axiosApi.get(
        `/v1/clinic/getClinicsNearby?page=${page}&pageSize=${pageSize}&location=${locationQuery}&keyword=${searchQuery}`
      );
      setNearClinic(response?.data?.data || []);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [page, searchQuery, locationQuery]);

  return (
    <section id="doctors">
      <div className="st-height-b120 st-height-lg-b80" />
      <SectionHeading title="Clinics near you" />
      <div className="container">
        {loading ? (
          <div className="custom-loader_container">
            <span className="custom-loader"></span>
          </div>
        ) : (
          <>
            <div className="hospital_near_you-slider-container">
              {nearclinic?.map((clinic, index) => (
                <Link
                  key={clinic.clinic_id}
                  to={`/clinic/${encodeDoctorId(clinic.clinic_id)}`}
                >
                  <div key={index} className="hospital_near_you-card-container">
                    <img
                      className="hospital_near_you-card-image"
                      // src={
                      //   doctor.photo
                      //     ? `${imageBase_URL}${doctor.photo}`
                      //     : `${process.env.PUBLIC_URL}/images/empty-user.png`
                      // }
                      src={
                        clinic?.banner_img_url
                          ? `${imageBase_URL}${clinic?.banner_img_url}`
                          : `${process.env.PUBLIC_URL}/images/empty-user.png`
                      }
                      alt={clinic.name || "Doctor Image"}
                    />
                    <p className="hospital_near_you-card-name">
                      {clinic?.name}, {clinic?.address}
                    </p>
                    <p className="hospital_near_you-card-location">
                      {clinic?.place}
                    </p>
                    <p className="hospital_near_you-card-distance">
                      <span className="hospital_near_you-card-distance-value">
                        {clinic?.distance} {clinic?.distance_unit}
                      </span>
                      <span className="hospital_near_you-card-distance-text">
                        away
                      </span>
                    </p>
                  </div>
                </Link>
              ))}
            </div>
          </>
        )}
      </div>
      <div className="st-height-b120 st-height-lg-b80" />
    </section>
  );
};

export default HospitalsAvailable;
