import React, { useContext, useEffect } from "react";
import Contact from "../../Components/Contact/Contact";
import SectionHeading from "../../Components/SectionHeading/SectionHeading";
import { Link } from "react-router-dom";
import HeroSlider2 from "../../Components/Slider/HeroSlider2";

const LandingPage = () => {
  const heroData = {
    // bgImg: "images/landing_page.png",
    bgImg: "images/partner-with-us-bg.png",
    bgShape: "shape/hero-shape.png",
    sliderImages: [
      {
        img: "images/partner-with-us-Doctors.png",
      },
    ],
  };

  return (
    <>
      <div
        id="home"
        className="st-hero-wrap st-gray-bg st-dynamic-bg overflow-hidden st-fixed-bg"
        style={{ backgroundImage: `url(${heroData.bgImg})` }}
      >
        <div className="partner-with-us-hero-style">
          <div className="container">
            <div className="st-hero-text">
              <div className="st-height-b40 st-height-lg-b40" />

              <h3 className="cd-headline slide" style={{ color: "white" }}>
                myDoctorsTime
              </h3>

              <h1
                className="partner-with-us-image-head"
                style={{ color: "white" }}
              >
                JOIN OUR NETWORK OF
                <br />
                LEADING CLINICS AND
                <br />
                HEALTHCARE
                <br />
                PROVIDES
              </h1>

              <div className="st-hero-btn-container">
                <div className="st-hero-btn">
                  <Link
                    to={"/owner-signup"}
                    className="st-btn st-style1 st-color1 st-smooth-move"
                  >
                    Sign up as Owner
                  </Link>
                </div>
                <div className="st-hero-btn ">
                  <Link
                    to={"/doctor-signup"}
                    className="st-btn st-style1 st-color1 st-smooth-move st-hero-outline-btn"
                  >
                    Sign up as Doctor
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <HeroSlider2 data={heroData.sliderImages} />
      </div>

      <section id="landingPage">
        {/* <div className="st-height-b120 st-height-lg-b80" /> */}
        <div className="st-height-b25 st-height-lg-b25" />
        <div className="container">
          <div>{/* <img src="images/landing_page.png" /> */}</div>
          <div className="st-height-b25 st-height-lg-b25" />
          <div>
            <h2 className="partner-with-us-image-head">
              Join Our Network of Leading Clinics and Healthcare Providers
            </h2>
            <div className="st-height-b25 st-height-lg-b25" />
            <p style={{ fontSize: "16px" }}>
              At myDoctorsTime, we are committed to revolutionizing the
              healthcare experience for both providers and patients. By
              partnering with us, you gain access to a comprehensive,
              user-friendly platform that streamlines clinic operations,
              enhances patient engagement, and boosts your clinic's visibility
              and growth. Discover the features and benefits of our platform:
            </p>
            <div className="st-height-b25 st-height-lg-b25" />
            <h2 className="partner-with-us-image-head">Why Partner With Us?</h2>
            <div className="st-height-b25 st-height-lg-b25" />
            <div>
              <ol>
                <li style={{ marginBottom: "10px" }}>
                  <strong className="partner-with-us-list-contents">
                    Comprehensive Management Tools:
                  </strong>
                  <ul style={{ fontSize: "16px" }}>
                    <li>
                      Effortlessly manage your clinic’s operations, from
                      scheduling appointments to tracking payments.
                    </li>
                    <li>
                      Dedicated dashboards for owners, clinic admins, and
                      doctors to manage their specific responsibilities
                      effectively.
                    </li>
                  </ul>
                </li>
                <li style={{ marginBottom: "10px" }}>
                  <strong className="partner-with-us-list-contents">
                    Advanced Search Capabilities:
                  </strong>
                  <ul style={{ fontSize: "16px" }}>
                    <li>
                      Patients can search for doctors based on location,
                      department, and clinic, ensuring they find the right
                      healthcare provider quickly and easily.
                    </li>
                  </ul>
                </li>
                <li style={{ marginBottom: "10px" }}>
                  <strong className="partner-with-us-list-contents">
                    Analytics & Reporting:
                  </strong>
                  <ul style={{ fontSize: "16px" }}>
                    <li>
                      Gain insights into your clinic’s performance with detailed
                      analytics and trend reports (Daily, Monthly, Yearly).
                    </li>
                    <li>
                      Monitor key metrics such as total bookings, top-performing
                      doctors, and revenue trends.
                    </li>
                  </ul>
                </li>
                <li style={{ marginBottom: "10px" }}>
                  <strong className="partner-with-us-list-contents">
                    Flexible Appointment Management:
                  </strong>
                  <ul style={{ fontSize: "16px" }}>
                    <li>
                      Allow patients to book appointments online through a
                      seamless, user-friendly interface.
                    </li>
                    <li>
                      Manage walk-in and emergency bookings, reschedule
                      appointments, and handle cancellations efficiently.
                    </li>
                  </ul>
                </li>
                <li style={{ marginBottom: "10px" }}>
                  <strong className="partner-with-us-list-contents">
                    Doctor and Clinic Profiles:
                  </strong>
                  <ul style={{ fontSize: "16px" }}>
                    <li>
                      Showcase detailed profiles of doctors, including their
                      qualifications, specializations, availability, and patient
                      reviews.
                    </li>
                    <li>
                      Highlight your clinic’s unique services and specialties
                      with customizable profiles.
                    </li>
                  </ul>
                </li>
                <li style={{ marginBottom: "10px" }}>
                  <strong className="partner-with-us-list-contents">
                    Subscription Plans:
                  </strong>
                  <ul style={{ fontSize: "16px" }}>
                    <li>
                      Choose from basic and premium subscription plans tailored
                      to meet the needs of different clinics.
                    </li>
                    <li>
                      Manage subscriptions, view payment history, and receive
                      notifications for renewals and pending payments.
                    </li>
                  </ul>
                </li>
                <li style={{ marginBottom: "10px" }}>
                  <strong className="partner-with-us-list-contents">
                    Payment History:
                  </strong>
                  <ul style={{ fontSize: "16px" }}>
                    <li>Secure and convenient payment processing.</li>
                    <li>
                      Comprehensive payment history and billing management for
                      transparency and ease of use.
                    </li>
                  </ul>
                </li>
                <li style={{ marginBottom: "10px" }}>
                  <strong className="partner-with-us-list-contents">
                    Notification System:
                  </strong>
                  <ul style={{ fontSize: "16px" }}>
                    <li>
                      Stay connected with real-time notifications and
                      announcements for clinics, doctors, and patients.
                    </li>
                    <li>
                      Automated reminders for appointments, subscription
                      renewals, and important updates.
                    </li>
                  </ul>
                </li>
                <li style={{ marginBottom: "10px" }}>
                  <strong className="partner-with-us-list-contents">
                    Token and Queue Management:
                  </strong>
                  <ul style={{ fontSize: "16px" }}>
                    <li>
                      Streamline patient flow with a token system that displays
                      token numbers on screens and manages patient queues.
                    </li>
                    <li>
                      Notify patients about their queue status and expected
                      waiting time.
                    </li>
                  </ul>
                </li>
                <li>
                  <strong className="partner-with-us-list-contents">
                    Communication Tools:
                  </strong>
                  <ul style={{ fontSize: "16px" }}>
                    <li>
                      Send and receive SMS and email notifications for
                      appointment confirmations, cancellations, and other
                      important updates.
                    </li>
                  </ul>
                </li>
              </ol>
            </div>
            <div className="st-height-b25 st-height-lg-b25" />
            <h2 className="partner-with-us-image-head">
              Exclusive Features for Our Partners
            </h2>
            <div className="st-height-b25 st-height-lg-b25" />
            <div>
              <ol>
                <li style={{ marginBottom: "10px" }}>
                  <strong className="partner-with-us-list-contents">
                    Owner Dashboard:
                  </strong>
                  <ul style={{ fontSize: "16px" }}>
                    <li>
                      <strong style={{ color: "black" }}>
                        Account Management:
                      </strong>{" "}
                      Sign up through a referral link, manage personal and
                      clinic details, and update subscription plans.
                    </li>
                    <li>
                      <strong style={{ color: "black" }}>
                        Clinic Management:
                      </strong>{" "}
                      Add and manage multiple clinics, assign doctors, and
                      oversee clinic operations.
                    </li>
                    <li>
                      <strong style={{ color: "black" }}>
                        Doctor Management:
                      </strong>{" "}
                      Manage doctor profiles, assign schedules, and track
                      performance.
                    </li>
                    <li>
                      <strong style={{ color: "black" }}>Analytics:</strong>{" "}
                      Monitor clinic performance with detailed reports and trend
                      analysis.
                    </li>
                  </ul>
                </li>
                <li style={{ marginBottom: "10px" }}>
                  <strong className="partner-with-us-list-contents">
                    Clinic Admin Dashboard:
                  </strong>
                  <ul style={{ fontSize: "16px" }}>
                    <li>
                      <strong style={{ color: "black" }}>
                        Clinic Operations:
                      </strong>{" "}
                      Manage clinic-specific settings, update clinic
                      information, and generate appointment links.
                    </li>
                    <li>
                      <strong style={{ color: "black" }}>
                        Doctor Management:
                      </strong>{" "}
                      Add and manage doctors, set consultation schedules, and
                      handle patient queues
                    </li>
                    <li>
                      <strong style={{ color: "black" }}>
                        Appointment Management:
                      </strong>{" "}
                      View and manage all appointments, provide walk-in and
                      emergency booking options.
                    </li>
                  </ul>
                </li>
                <li style={{ marginBottom: "10px" }}>
                  <strong className="partner-with-us-list-contents">
                    Doctor Dashboard:
                  </strong>
                  <ul style={{ fontSize: "16px" }}>
                    <li>
                      <strong style={{ color: "black" }}>
                        Profile Management:
                      </strong>{" "}
                      Update personal profile, specialization, and consultation
                      schedules.
                    </li>
                    <li>
                      <strong style={{ color: "black" }}>
                        Appointment Management:
                      </strong>{" "}
                      View and manage bookings, send digital prescriptions, and
                      update patient records.
                    </li>
                    <li>
                      <strong style={{ color: "black" }}>Analytics:</strong>{" "}
                      Manage doctor profiles, assign schedules, and track
                      performance.
                    </li>
                    <li>
                      <strong style={{ color: "black" }}>Analytics:</strong>{" "}
                      Track daily bookings, follow-ups, and new patients with
                      comprehensive analytics.
                    </li>
                  </ul>
                </li>
              </ol>
            </div>
            <div className="st-height-b25 st-height-lg-b25" />
            <h2 className="partner-with-us-image-head">Registration Options</h2>
            <div className="st-height-b25 st-height-lg-b25" />
            <div>
              <ol>
                <li style={{ marginBottom: "10px" }}>
                  <strong className="partner-with-us-list-contents">
                    Owner Registration:
                  </strong>
                  <p style={{ fontSize: "16px", marginTop: "10px" }}>
                    As a clinic owner, you can register your clinics and manage
                    your operations seamlessly. Here’s what you get:
                  </p>
                  <ul style={{ fontSize: "16px" }}>
                    <li>
                      <strong style={{ color: "black" }}>
                        Multi-Clinic Management:
                      </strong>{" "}
                      Add and manage multiple clinics under one account.
                    </li>
                    <li>
                      <strong style={{ color: "black" }}>
                        Doctor Management:
                      </strong>{" "}
                      Register doctors, assign them to clinics, and manage their
                      schedules.
                    </li>
                    <li>
                      <strong style={{ color: "black" }}>
                        Subscription Management:
                      </strong>{" "}
                      Choose from basic and premium plans to suit your clinic's
                      needs.
                    </li>
                    <li>
                      <strong style={{ color: "black" }}>
                        Comprehensive Dashboard:
                      </strong>{" "}
                      Access detailed analytics, payment history, and
                      performance reports.
                    </li>
                  </ul>
                </li>
                <Link to={"/owner-signup"}>
                  <button type="submit" className="signup_submit_btn">
                    Sign up as Owner
                  </button>
                </Link>
                <div className="st-height-b25 st-height-lg-b25" />

                <li style={{ marginBottom: "10px" }}>
                  <strong className="partner-with-us-list-contents">
                    Doctor Registration:
                  </strong>
                  <p style={{ fontSize: "16px", marginTop: "10px" }}>
                    Join our network of healthcare providers and enhance your
                    practice. Here’s what you get:
                  </p>
                  <ul style={{ fontSize: "16px" }}>
                    <li>
                      <strong style={{ color: "black" }}>
                        Profile Visibility:
                      </strong>{" "}
                      Create a detailed profile to attract more patients.
                    </li>
                    <li>
                      <strong style={{ color: "black" }}>
                        Appointment Management:
                      </strong>{" "}
                      Easily manage your bookings and schedules.
                    </li>
                    <li>
                      <strong style={{ color: "black" }}>Analytics:</strong>{" "}
                      Track your performance with detailed reports and
                      analytics.
                    </li>
                    <li>
                      <strong style={{ color: "black" }}>
                        Patient Engagement:
                      </strong>{" "}
                      Communicate with patients and provide better care.
                    </li>
                  </ul>
                </li>
                <Link to={"/doctor-signup"}>
                  <button type="submit" className="signup_submit_btn">
                    Sign up as Doctor
                  </button>
                </Link>
                <div className="st-height-b25 st-height-lg-b25" />
              </ol>
            </div>
            <div className="st-height-b25 st-height-lg-b25" />
            <h2 className="partner-with-us-image-head">Partner Testimonials</h2>
            <div className="st-height-b25 st-height-lg-b25" />
            <p style={{ fontSize: "16px" }}>
              "Joining myDoctorsTime has transformed the way we manage our
              clinic. The platform is intuitive, and the support team is always
              ready to help." - Dr. John Doe, Clinic Owner
            </p>
            <p style={{ fontSize: "16px" }}>
              "The analytics and reporting tools have given us insights that we
              never had before. We can now make informed decisions to improve
              our services." - Jane Smith, Clinic Admin
            </p>
            <div className="st-height-b25 st-height-lg-b25" />
            <h2 className="partner-with-us-image-head">
              Ready to Transform Your Clinic Operations?
            </h2>
            <div className="st-height-b25 st-height-lg-b25" />
            <p style={{ fontSize: "16px" }}>
              Partner with myDoctorsTime today and take the first step towards a
              more efficient, patient-friendly clinic experience. Contact us to
              learn more about how we can help your clinic thrive.
            </p>
          </div>
        </div>
        <div className="st-height-b120 st-height-lg-b80" />
      </section>
      <Contact />
    </>
  );
};

export default LandingPage;
